.home-gallery-wrap,
.event-gallery-wrap {
  background-color: #252525;
  padding: 100px 0;
  margin-bottom: 100px;
  @media (max-width: 950px) {
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 50px 0;
  }

  .gallery-wrap {
    width: 100%;
    min-height: 400px;
    max-height: 550px;
    // height: 350px;

    // @media (min-width: 601px) {
    //   height: 400px;
    // }

    // @media (min-width: 901px) {
    //   height: 450px;
    // }

    // @media (min-width: 1201px) {
    //   height: 550px;
    // }

    .swiper {
      height: 100%;
      max-height: 550px;
      min-height: 400px;
      width: 100%;
      position: relative;
      img {
        height: 100%;
        object-position: center;
      }

      .swiper-button-prev,
      .swiper-button-next {
        background-color: #eecf3b;
        opacity: 0.85;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 1;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        border-radius: 50%;
        cursor: pointer;
        &::after {
          content: "";
          width: 30px;
          height: 30px;
          background-image: url(../../img/graphic_assets/arrow.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        @media (max-width: 650px) {
          width: 35px;
          height: 35px;
          &::after {
            width: 25px;
            height: 25px;
          }
        }
      }
      .swiper-button-next {
        right: 0;
        left: initial;
        &::after {
          transform: scale(-1, -1);
        }
      }
    }

    .swiper-slide {
      height: 100%;
      max-height: 550px;
      min-height: 400px;
      aspect-ratio: 1/1;
      img {
        height: 100%;
        object-fit: cover;
        min-height: 400px;
        max-height: 550px;
      }
    }
  }
  .gallery-btn {
    background-color: #eecf3b;
    color: black;
    padding-right: 50px;
    position: relative;
    padding: 10px 50px 10px 20px;
    border-radius: 6px;
    display: block;
    margin: 10px auto 10px;
    margin-top: 50px;
    width: fit-content;
    align-self: end !important;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    &::before {
      background-image: url(../../img/graphic_assets/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 20px;
      height: 20px;
      border: none;
      content: "";
      position: absolute;
      transform: scale(-1, -1) translateY(50%);
      top: 50%;
      right: 15px;
    }
  }
  h2 {
    text-align: center;
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    margin-bottom: 50px;
    @media (max-width: 950px) {
      margin-bottom: 50px;
    }
    @media (max-width: 1200px) {
      line-height: 52px;
      font-size: 46px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 44px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
}
.home-gallery-wrap {
  margin-bottom: 150px;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 850px) {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.event-gallery-wrap {
  padding: 50px 0;
  margin: 0;
  .gallery-wrap {
    padding: 50px 0;
  }
  h2 {
    font-size: 32px;
    margin: 0;
  }
}
