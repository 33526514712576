.prices-section {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  background-color: #f2f2f2;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  .small {
    margin-top: -25px;
    font-weight: 300;
  }
  .img-wrap {
    height: 100%;
    @media (max-width: 1200px) {
      max-height: 500px;
    }
    @media (max-width: 850px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 1200px) {
        object-position: 0 80%;
      }
    }
  }
  .prices-schedule-wrap {
    padding: 100px 0;
    .price-switch-wrap {
      margin: 0 auto 50px;
      @media (max-width: 850px) {
        margin: 0 auto 20px;
      }
      p {
        text-align: center;
      }
      .price-switch {
        width: fit-content;
        margin: 10px auto;

        gap: 10px;
        border-radius: 8px;
        display: flex;
        .currency {
          padding: 2px 15px;
          transition: 0.3s;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          display: block;
          border-radius: 8px;
          background-color: white;
          font-weight: 400;
          &.active {
            background-color: #eecf3b;
          }
        }
      }
    }
    .promo-text {
      text-align: center;
      margin: 20px auto;
      display: block;
      width: 90%;
    }
    .cold-note {
      display: inline;
    }
    h3 {
      font-size: 50px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 20px;
      width: 100%;
      padding: 0 20px 10px;
      text-align: center;
      color: black;
      @media (max-width: 1200px) {
        font-size: 46px;
      }
      @media (max-width: 850px) {
        margin-bottom: 0px;
      }
      @media (max-width: 800px) {
        font-size: 38px;
        line-height: 44px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    justify-items: center;
    @media (max-width: 950px) {
      padding: 50px 0;
    }
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .soon {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 250px;

      min-height: 100px;
      z-index: 10;
      background-color: white;
      transform: translate(-50%, -50%);
      padding: 50px;
      border-radius: 8px;
      box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.2);
      @media (max-width: 1000px) {
        padding: 50px 20px;
      }
      p {
        font-weight: 500;
        font-size: 1.2rem;
        text-align: center;
      }
      h5 {
        font-size: 1.5rem;
        line-height: 1.6rem;
        text-align: center;
        margin: 0 0 10px 0;
      }
    }
    .schedule-wrap,
    .prices-wrap {
      @media (max-width: 650px) {
      }
      p,
      h3,
      span {
        color: white;
      }
    }
    .schedule-wrap {
      // filter: blur(2px);
      padding: 0 50px;
      display: flex;
      gap: 20px;
      align-items: stretch;
      justify-content: center;
      @media (max-width: 850px) {
        flex-direction: column;
        min-width: 600px;
        align-items: center;
      }
      @media (max-width: 650px) {
        padding: 0 20px;
        min-width: 340px;
        width: 90%;
      }
      @media (max-width: 550px) {
        width: 100%;
      }
      .mobile-wrap {
        position: relative;
        .price {
          padding: 5px 10px;

          border-radius: 6px 0 0 6px;
        }
        @media (max-width: 850px) {
          display: flex;
          justify-content: space-between;
          gap: 50px;
          .price {
            font-size: 22px !important;
          }
        }
        @media (max-width: 500px) {
          gap: 10px;
        }
      }
      .head-wrap {
        display: grid;
        grid-template-columns: 40px auto auto;
        padding-left: 20px;

        gap: 10px;
        align-items: center;
        width: 100%;
        .price {
          justify-self: flex-end !important;
          padding-right: 20px;
        }

        @media (max-width: 500px) {
          gap: 10px;
        }
      }
      .schedule-inner-wrap {
        width: 100%;
        padding: 30px 0;
        background-color: #1d1b19;
        border-radius: 20px;
        max-width: 450px;
        .deps,
        .times-wrap {
          padding: 0 20px;
        }
        .discount-text {
          font-style: italic;
          padding: 0 30px;
          text-align: center;
          position: relative;
          width: fit-content;
          margin: auto;
          &::before {
            content: "*";
          }
        }
        .price-wrapper {
          padding-right: 20px;
          > div {
            width: fit-content;
            margin-left: auto;
          }
          p {
            margin-bottom: 0 !important;
            width: fit-content;
            b {
              font-size: 20px;
            }
          }
          position: relative;
          // &::after {
          //   content: "(5500 HUF-tól)";
          //   position: absolute;
          //   bottom: -100%;
          //   left: 0;
          // }
          // &.day {
          //   &::after {
          //     content: "(4320 HUF-tól)";
          //   }
          // }
          .forint-wrapper {
            margin-bottom: 0;
          }
        }
        .type-wrap {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .type {
            display: flex;
            align-items: center;

            gap: 10px;
            span {
              line-height: initial;
            }
          }
        }

        .to-ticket {
          background-color: #eecf3b;
          color: black;
          padding-right: 50px;
          position: relative;
          padding: 10px 50px 10px 20px;
          border-radius: 6px;
          cursor: pointer;
          display: block;
          margin: 10px auto 10px;
          width: fit-content;
          align-self: end !important;
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          &::before {
            background-image: url(../../img/graphic_assets/arrow.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 20px;
            height: 20px;
            border: none;
            content: "";
            position: absolute;
            transform: scale(-1, -1) translateY(50%);
            top: 50%;
            right: 15px;
          }
        }
        b {
          font-size: 18px;
        }

        .extra {
          color: #eecf3b;
        }
        // .discount-wrap {
        //   position: relative;
        //   .discount-box {
        //     position: absolute;

        //     background-color: #bf382b;
        //     color: white;
        //     padding: 0px 5px;
        //     right: 0px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     top: -25px;
        //     span {
        //       color: white;
        //     }
        //   }
        // }
        .price {
          font-weight: 500;
          font-size: 20px;
          margin: 0;

          &.discount {
            text-decoration: line-through;
            font-size: 14px !important;
            font-weight: 300;

            // &::after {
            //   content: "-25%";
            //   position: absolute;
            //   top: -20px;
            //   background-color: #bf382b;
            //   color: white;
            //   padding: 0px 5px;
            //   right: -10px;
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            // }
          }
        }
        // .discount-price {
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-end;
        //   gap: 10px;
        //   p {
        //     font-weight: 500;

        //     margin: 0;
        //     width: 100%;
        //     justify-self: flex-end;
        //     &.old {
        //       text-decoration: line-through;
        //       font-size: 14px;
        //       font-weight: 400;
        //       position: absolute;
        //       top: -15px;
        //       right: 20px;
        //     }
        //     &.new {
        //       white-space: nowrap;
        //       width: initial;
        //     }
        //   }
        //   position: relative;
        //   width: 100%;
        //   max-width: 150px;
        //   text-align: end;
        //   .percent {
        //     content: "-25%";
        //     // position: relative;
        //     // left: -15px;
        //     // top: 5px;
        //     // transform: translate(0, -50%);
        //     background-color: #bf382b;
        //     color: white;
        //     padding: 0 5px;
        //     font-size: 14px;
        //   }
        // }

        .deps {
          margin: 30px 0 10px;
          opacity: 0.8;
          font-size: 18px;
          font-weight: 400;
          padding-left: 40px;
          @media (max-width: 850px) {
            font-size: 16px;
          }
        }
        .type {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 0;
          @media (max-width: 1200px) {
            font-size: 20px;
          }
        }
        .times-wrap {
          display: flex;
          flex-wrap: wrap;
          column-gap: 20px;
          row-gap: 5px;
          margin: 0 0 20px 0;
          padding-left: 40px;
          padding-right: 40px;
          .time-wrap {
            display: flex;
            justify-content: space-between;
            width: fit-content;

            padding: 5px 0;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            &:last-of-type {
              border-bottom: none;
              margin-bottom: 0;
            }
            > div {
              display: grid;
              grid-template-columns: 50px 1fr;
              gap: 10px;
            }
            .time {
              font-size: 24px;

              font-weight: 500;
              position: relative;
            }
            .discount {
              font-size: 12px;
              // font-weight: 400;
              width: 40px;
              height: 20px;
              align-self: center;
              background-color: #e5203b;
              display: flex;
              align-items: center;
              justify-self: flex-end;
              border-radius: 3px;
              justify-content: center;
            }
          }
        }
        .extra-desc {
          margin: 30px 0;
          font-size: 16px;
          font-weight: 300;
          @media (max-width: 1200px) {
            font-size: 16px;
          }
        }
        &.day {
          background-color: white;
          box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.2);
          .time-wrap {
            span {
              color: black;
            }
            // border-bottom: 1px solid rgba(0, 0, 0, 0.5);

            .discount {
              color: white;
            }
          }
          .time {
            color: black;
          }
          .discount-text {
            color: black;
          }

          .mobile-wrap {
            * {
              color: black;
            }
          }
          .deps {
            color: black;
          }
          .head-wrap {
            grid-template-columns: 40px auto auto;
          }
        }
      }
    }
    .divider {
      height: 100%;
      width: 1px;
      background-color: white;
      opacity: 0.5;
      @media (max-width: 850px) {
        width: 10px;
        height: 10px;

        background-color: #eecf3b;
        transform: rotate(45deg);
        opacity: 1;
        margin-bottom: 20px;
        &::after {
          content: "";
          width: 5px;
          height: 5px;
          position: absolute;
          bottom: -5px;
          left: -5px;
          background-color: #eecf3b;
        }
        &::before {
          content: "";
          width: 5px;
          height: 5px;
          position: absolute;
          top: -5px;
          right: -5px;
          background-color: #eecf3b;
        }
      }
    }
    .prices-wrap {
      // border-left: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 50px;
      width: 100%;
      @media (max-width: 650px) {
        padding: 0 20px;
      }
      .tickets-block__item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 15px;
        position: relative;
        sup {
          opacity: 0.8;
          font-weight: 300;
          color: white;
        }
        &.line {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: white;
            opacity: 0.5;
          }
        }
        .ticket__title,
        .ticket__price {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }
        .ticket__title {
          text-transform: uppercase;
        }
        .ticket__addendum {
          font-size: 16px;
          opacity: 0.8;
          font-weight: 300;
          @media (max-width: 650px) {
            font-size: 14px;
          }
        }
        &.extra-kid {
          align-items: baseline;
          gap: 50px;
        }
        .ticket__details {
          &.ticket__sub-param {
            font-size: 16px;
            opacity: 0.8;
            font-weight: 300;
            @media (max-width: 1200px) {
              font-size: 16px;
            }
          }
        }
      }
      .to-ticket {
        background-color: #eecf3b;
        color: black;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 400;
        padding-right: 50px;

        position: relative;
        &::before {
          background-image: url(../../img/graphic_assets/arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 20px;
          height: 20px;
          border: none;
          content: "";
          position: absolute;
          transform: scale(-1, -1) translateY(50%);
          top: 50%;
          right: 15px;
        }
      }
    }
    .info-wrapper {
      display: flex;
      grid-column: 1/4;
      max-width: 750px;
      width: 90%;
      margin: 30px auto 0;
      box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.2);

      background-color: white;
      border-radius: 20px;
      align-items: center;
      @media (max-width: 850px) {
        margin: 50px;
        &.cold {
          margin: 20px;
          margin-bottom: -20px;
        }
      }
      @media (max-width: 650px) {
        margin: 20px;
        // flex-direction: column;
      }
      &:not(.cold) {
        margin-top: 10px;
      }
      &.cold {
        background-color: #eecf3b;
        box-shadow: none;
        .info-text-wrapper {
          p {
            b {
              display: block;
              text-transform: uppercase;
            }
          }
        }
      }
      img {
        width: 100px;
        height: 100px;
        padding: 20px;
        @media (max-width: 650px) {
          width: 40px;
          height: 40px;
          padding: 0 0 0 10px;
        }
      }
      .info-text-wrapper {
        // border-left: 2px solid #f2f2f2;
        // padding: 0 20px;
        p {
          color: black;
          opacity: 0.8;
          font-size: 15px;
          font-weight: 300;
          padding: 20px 20px 20px 0;
          @media (max-width: 1200px) {
            font-size: 14px;
          }
          @media (max-width: 650px) {
            font-size: 13px;
            padding: 10px 10px;
          }
        }
        @media (max-width: 650px) {
          border: none;
          padding: 0;
        }
      }
    }
    .promo-text {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 5px;
      b {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        margin: 0 5px;
      }
      p {
        align-items: center;
        display: flex;
        justify-content: center;
        column-gap: 5px;
        flex-wrap: wrap;
      }
    }
    .copy-btn {
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      width: fit-content;
      display: inline-block;
      align-self: baseline;
      cursor: pointer;
      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
