a {
  text-decoration: none;
}
.nav--section {
  // a {
  //   &.hamburger-toggle {
  //     font-size: 0.9em;
  //     color: white;
  //     font-weight: 400;
  //     display: inline;
  //   }
  // }
  .language-wrapper {
    position: relative;
  }
  .ticket {
    background-color: #eecf3b;
    border-radius: 4px;
    a {
      color: black !important;
    }
    @media (max-width: 990px) {
      background-color: transparent;
      a {
        color: white !important;
      }
    }
  }
}

#fareharbor-cartframe {
  display: none !important;
}
#fareharbor-cartframe-iframe {
  & iframe {
    display: none !important;
  }
}

.box-office-wrap {
  background-color: #f2f2f1;
  padding: 0 0 100px 0;
  @media (max-width: 950px) {
    padding-bottom: 50px;
  }
  h3 {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    line-height: 50px;
    margin: auto;
    padding: 0 20px 10px;
    max-width: 800px;
    @media (max-width: 1200px) {
      line-height: 52px;
      font-size: 46px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 44px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .to-ticket {
    background-color: #eecf3b;
    color: black;
    padding: 10px 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    padding-right: 50px;

    position: relative;
    &::before {
      background-image: url(../img/graphic_assets/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 20px;
      height: 20px;
      border: none;
      content: "";
      position: absolute;
      transform: scale(-1, -1) translateY(50%);
      top: 50%;
      right: 15px;
    }
  }
  .img-text-wrap {
    display: flex;
    align-items: center;
    padding: 50px 100px 0;
    gap: 50px;
    max-width: 1600px;
    margin: 0 auto;
    @media (max-width: 950px) {
      padding: 50px 50px 0;
      flex-direction: column;
    }
    @media (max-width: 650px) {
      padding: 50px 20px 0;
    }
    .departures__img-container {
      flex-basis: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
    .departures__text-container {
      flex-basis: 50%;
      p {
        margin-bottom: 30px;
        line-height: 24px;
        font-size: 16px;
        color: black;
        font-weight: 300;
        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 24px;
        }
        &.large {
          font-size: 16px;
          line-height: 26px;
          flex-basis: 50%;

          @media (max-width: 600px) {
            font-size: 16px;
            line-height: 26px;
            font-weight: 600;
            padding-left: 15px;
            border-left: 10px solid #eecf3b;
          }
        }
      }
    }
  }
}

.video-wrap {
  padding: 0 0 100px 0;

  @media (max-width: 950px) {
    padding: 0 0 50px 0;
  }
  @media (max-width: 550px) {
    padding: 0;
  }
  .video__container {
    padding: 0 100px;
    border-radius: 20px;
    @media (max-width: 950px) {
      padding: 0 50px;
    }
    @media (max-width: 550px) {
      padding: 0;
    }
    video {
      border-radius: 20px;
      @media (max-width: 550px) {
        border-radius: 0;
      }
    }
  }
  .react-player__preview {
    border-radius: 20px;
    position: relative;
    @media (max-width: 550px) {
      border-radius: 0;
    }
  }
  .video__title-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    z-index: 10;

    > .video__title {
      color: white;
    }

    > .video__btn {
      width: 64px;
      height: 64px;
      top: 0;
      left: 0;
      transition: all 0.5s ease;
      display: block;
      z-index: 1;
      transform-origin: center;
      transform: scale(1);
      opacity: 1;

      &.hiding {
        opacity: 0;
        transform: scale(0.7);
      }

      &.play {
        background-image: url(../img/icons/play-button-primary-col.svg);
        top: 0;
        left: 0;
        opacity: 1;
      }
    }
  }
}

.contact-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 100px 0 150px;
  gap: 50px;
  background-color: white;
  align-items: center;
  @media (max-width: 950px) {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 650px) {
    padding-bottom: 0;
    padding-top: 50px;
  }
  .img-wrap {
    flex-basis: 50%;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 100px;
    height: 450px;
    @media (max-width: 950px) {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .text-wrapper {
    flex-basis: 50%;
    overflow: hidden;
    @media (max-width: 950px) {
      padding-left: 50px;
      padding-right: 50px;
      margin: 0;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    h3 {
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      padding-right: 20px;
      padding-bottom: 10px;
      line-height: 50px;
      max-width: 800px;
      margin: 0;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: calc(100% + 1500px);
        background-color: black;
        bottom: 0;
        left: -70px;
      }
      @media (max-width: 1200px) {
        line-height: 52px;
        font-size: 46px;
      }
      @media (max-width: 800px) {
        font-size: 38px;
        line-height: 44px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    .inner-text {
      padding: 50px 50px 0 0;
      max-width: 500px;
      @media (max-width: 950px) {
        padding: 50px 0;
        margin: 0 auto;
      }
      .contacts-wrapper {
        padding-left: 30px;
        @media (max-width: 550px) {
          padding: 0;
        }
        .email,
        .phone {
          font-weight: 600;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: -25px;
            width: 10px;
            height: 10px;
            background-color: #eecf3b;
            transform: rotate(45deg);
          }
          @media (max-width: 550px) {
            padding-left: 15px;
            border-left: 10px solid #eecf3b;
            &::before {
              content: none;
            }
          }
        }
        .phone {
          margin-bottom: 0;
        }
        .phone-open {
          // font-size: 16px;
          margin-top: -5px;
          @media (max-width: 550px) {
            padding-left: 25px;
          }
        }
      }
      p {
        color: black;
        margin-bottom: 20px;
        line-height: 30px;
        font-size: 16px;
        font-weight: 300;
        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .open-wrapper {
        padding: 20px 30px 0 0;

        border-radius: 20px;
        .working-hours {
          margin: 0;
          font-weight: 600;
        }
        b {
          font-weight: 600;
          color: black;
          font-size: 16px;
          border-bottom: 1px solid black;
          padding-bottom: 15px;
          display: block;
          @media (max-width: 1200px) {
            font-size: 16px;
            line-height: 24px;
          }
          @media (max-width: 550px) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          }
        }
        .reply {
          padding-top: 15px;
          margin: 0;
        }
        .reply-b {
          margin: 0;
        }
        @media (max-width: 550px) {
          background-color: transparent;
          padding: 20px 0;
          border-radius: 0;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
.reviews-section {
  background-color: #1d1b19;
  display: flex;
  padding: 100px 50px;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    padding: 100px 20px;
  }
  * {
    color: white;
    margin: 0;
  }
  .desktop-wrap {
    display: flex;
    padding: 50px 50px 50px 100px;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 800px;
    gap: 20px;
    @media (max-width: 1600px) {
      padding: 50px;
    }
    @media (max-width: 1400px) {
      margin: auto;
      align-items: center;
      h3 {
        text-align: center;
      }
    }
    @media (max-width: 850px) {
      padding: 0 0 50px 0;
    }
    h3 {
      color: white;
      font-size: 50px;
      line-height: 50px;
      font-weight: 500;
      text-transform: uppercase;
      @media (max-width: 1200px) {
        line-height: 52px;
        font-size: 46px;
      }
      @media (max-width: 800px) {
        font-size: 38px;
        line-height: 44px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    .rating-wrap {
      display: flex;
      gap: 20px;
      .rating-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
  .reviews-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    max-height: 650px;
    gap: 50px;
    row-gap: 75px;
    justify-content: center;
    width: 100%;
    min-width: 720px;
    @media (max-width: 1600px) {
      flex-direction: row;
      align-items: stretch;
      max-height: 600px;
      min-width: 780px;
    }
    @media (max-width: 1400px) {
      min-width: initial;
      max-height: none;
    }
    @media (max-width: 850px) {
      flex-direction: column;
      align-items: center;
      min-width: auto;
      padding: 0 20px;
      gap: 50px;
    }
    .review-item {
      max-width: 340px;
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &::before {
        content: "";
        width: 50px;
        height: 50px;
        position: absolute;
        bottom: -10px;
        background-color: white;
        left: 50%;
        transform: translate(-50%) rotate(45deg) skew(10deg, 10deg);
        border-radius: 5px;
        z-index: 0;
      }
      @media (max-width: 850px) {
        transform: translate(20px);
        &::before {
          transform: translate(10px, 50%) rotate(135deg) skew(10deg, 10deg);
          right: 5px;
          left: initial;
          bottom: 30%;
        }
        &:nth-of-type(even) {
          transform: translate(-20px);
          &::before {
            transform: translate(-10px, 50%) rotate(135deg) skew(10deg, 10deg);
            left: 5px;
            bottom: 70%;
          }
        }
      }
      @media (max-width: 650px) {
        transform: none;
        &::before {
          content: none;
        }
        &:nth-of-type(even) {
          transform: none;

          &::before {
            content: none;
          }
        }
      }

      p,
      span {
        color: black;
        font-weight: 400;
      }
      .author-wrap {
        position: absolute;
        top: -20px;
        padding: 5px 10px;
        border-radius: 8px;
        background-color: #eecf3b;
        p {
          font-weight: 400;
        }
      }
      .review-text-wrap,
      .stars-wrap {
        position: relative;
        z-index: 1;
      }
      .readmore {
        font-weight: 300;
        cursor: pointer;
      }
    }
  }
}
.notification--section {
  .seasonend {
    background-color: #eecf3b;
    border-radius: 20px;
    overflow: hidden;
    .fire-content-wrap {
      display: flex;
      border-radius: 20px;
      position: relative;
      @media (max-width: 1000px) {
        flex-direction: column;
        padding-top: 50px;
        max-width: 320px;
      }
      @media (max-width: 500px) {
      }
      .img-wrap {
        height: 400px;
        width: 400px;
        padding: 50px 0;
        position: relative;
        z-index: 1;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 0 12px 12px 0;
        }
        @media (max-width: 1000px) {
          height: 250px;
          padding: 20px 0;
          align-self: flex-end;
          width: 300px;
          img {
            border-radius: 12px 0 0 12px;
          }
        }
      }
    }
    .close {
      right: 15px;
      top: 15px;
    }
    .text-wrap {
      padding: 50px;
      position: relative;
      z-index: 1;
      align-self: flex-end;
      @media (max-width: 1000px) {
        padding: 0 20px 20px;
      }
    }
    .wave {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      object-fit: cover;
      z-index: 0;
      @media (max-width: 1000px) {
        height: 150px;
      }
    }
    h3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.mobile-h {
        display: none;
      }
      @media (max-width: 1000px) {
        display: none;
        &.mobile-h {
          display: flex;
          width: fit-content;
          margin: auto;
          span {
            z-index: 1;
          }
          .big {
            position: static;
          }
        }
      }
      span {
        color: white !important;

        background-color: #252525;
        font-weight: 800;
        padding: 5px 10px;

        width: fit-content;
        &.big {
          font-size: 72px;
          padding: 10px;
          position: relative;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          line-height: 56px;
        }
      }
    }
    .fire-text {
      .firework-text {
        font-weight: 400;
        font-size: 16px;
        margin: 0 auto;
        text-align: center;
        &::first-letter {
          text-transform: uppercase;
        }
        &.first {
          margin-top: 20px;
        }
        &.second {
          margin-bottom: 20px;
        }
      }

      .subtext {
        margin-bottom: 20px;
      }
    }
    .btn {
      width: fit-content;
      margin: auto;
      background-color: #252525;
      color: white;
      min-width: initial !important;
      padding: 10px 20px;
    }
    .close-btn {
      cursor: pointer;
      margin: 10px auto;
      font-weight: 400;
      padding: 5px 10px;

      width: fit-content;
      border-radius: 8px;
    }
  }
}
.notification-container {
  // display: flex;
  justify-content: center;
  position: relative;
  background-color: #f2f2f1;
  padding: 50px;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.2);
  @media (max-width: 1000px) {
    padding: 0;
  }
  .flex-wrap {
    display: grid;
    background-color: #eecf3b;
    position: absolute;
    top: -100px;
    // grid-template-columns: 1fr 2fr;
    // grid-template-rows: 220px;
    justify-content: center;
    align-items: center;

    min-width: 800px;
    .cold {
      margin: 5px auto;
      text-align: center;
      padding: 0 10px;
      p {
        display: inline;
        text-align: center;
      }
    }
    @media (max-width: 1000px) {
      width: 100%;
      align-items: center;
      position: static;
      justify-content: center;
      grid-template-columns: 1fr;
      min-width: initial !important;
      grid-template-rows: auto;
    }
    border-radius: 0;
    max-width: 1200px;
    .inner-wrap {
      display: flex;

      padding: 20px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      // gap: 10px;
      p {
        &.h {
          font-size: 18px;
          font-weight: 500;
        }
        &.sub {
          font-weight: 400;
          margin-top: 10px;
        }
        strong {
          font-weight: 500;
        }
        strong {
          font-weight: 400;
          color: black;
          white-space: nowrap;
        }
      }
      @media (max-width: 600px) {
      }
    }
    .img-wrap {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
        object-position: 0 79%;
      }
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
  .close {
    right: 15px;
    top: 15px;
  }
  .text-wrap {
    padding: 50px;
    position: relative;
    z-index: 1;
    align-self: flex-end;
    @media (max-width: 1000px) {
      padding: 0 20px 20px;
    }
  }
  .wave {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 75%;
    object-fit: cover;
    z-index: 0;
  }
  h3 {
    align-items: center;
    margin-bottom: 20px;

    &.mobile-h {
      display: none;
    }
    @media (max-width: 1000px) {
      &.mobile-h {
        display: flex;
        width: fit-content;
        margin: auto;
        span {
          z-index: 1;
        }
        .big {
          position: static;
        }
      }
    }
    span {
      color: white !important;
      background-color: #252525;
      font-weight: 800;
      padding: 5px 4px;
      margin: 0 -1px;
      font-size: 26px;
      line-height: 26px;
      width: fit-content;
      &.big {
        font-size: 72px;
        padding: 10px;
        position: relative;
        left: 0;

        line-height: 56px;
      }
    }
  }
  .flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .firework-text {
    font-weight: 400;
    font-size: 14px;
    margin: 0 !important;
    text-align: center;
    @media (max-width: 1000px) {
      text-align: center;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .btn {
    width: fit-content;

    background-color: #252525;
    color: white;
    min-width: initial !important;
    padding: 10px 20px;
    margin: 10px auto 0;
  }
  .close {
    cursor: pointer;
    margin: 10px auto;
    font-weight: 400;
  }
}

footer {
  width: 100%;
  .logo-wrap {
    padding: 0 20px;
    padding-top: 20px;
    width: 200px;
    min-height: 80px;
    justify-self: center;
    z-index: 1;
    img {
      width: 100%;
      object-fit: contain;
      max-height: initial;
    }
  }
  .flex-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-content: flex-start;
    justify-items: flex-start;
    @media (max-width: 950px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
  .contact {
    display: flex;
    flex-direction: column;

    @media (max-width: 950px) {
      grid-column: 2/3;
      @media (max-width: 600px) {
        grid-column: auto;
      }
    }

    .list-contact {
      display: flex !important;
      flex-direction: column;
    }
    .row {
      margin-right: 0 !important;
    }
  }
  .nav-section,
  .event-section,
  .contact {
    z-index: 1;
    @media (max-width: 600px) {
      justify-self: center;
      width: 100%;
      padding-left: 27px;
    }
    @media (max-width: 500px) {
      justify-self: center;
      width: 100%;
      padding-left: 0;
    }
  }
}
.spinner-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  &.hidden {
    display: none;
  }
}
.fixed-btn-wrap {
  &.hid {
    transform: translateY(100px);
    position: fixed;
    transition: 0.5s;

    bottom: 20px;
  }
  // @media (min-width: 800px) {
  //   display: none;
  // }
  right: 80px;
  bottom: 20px;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 26px;
  transform: translateY(0);

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  cursor: pointer;
  .ticket-btn {
    margin: 0 !important;

    width: 100%;
    height: 100%;
    align-items: center;

    border-radius: 6px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 10px 15px;
    position: relative;
    z-index: 1;
    img {
      width: 30px;
      height: 30px;
    }
  }
  span {
    color: white;
    text-transform: uppercase;
  }
}
.to-top {
  width: 50px;
  height: 50px;

  right: 20px;
  bottom: 20px;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  cursor: pointer;
  img {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
  }
  &.hidden-to-top {
    transform: translateY(100px);
  }
}
