.hero-wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  .hero-vid {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.mobile {
    background-image: none;
    background-color: #252525;
    display: flex;
    align-items: stretch;
    .hero-text {
      position: static;
      margin-top: 70px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      padding: 0;
      justify-content: center;
      h1 {
        margin-top: 20px;
        text-align: center;
        padding-bottom: 0;
        padding: 0 20px;
        &::after {
          content: none;
        }
      }
      p {
        text-align: center;
        padding: 0 20px;
        margin-bottom: 20px;
      }
      .btn-wrap {
        padding: 0 20px;
        justify-content: center;
      }
    }
  }
  .hero-text {
    width: 80%;
    max-width: 900px;
    padding: 0 50px;
    position: absolute;
    left: 0;
    bottom: 20%;
    @media (max-width: 850px) {
      width: 100%;
      padding: 0 20px;
    }
    h1,
    p {
      color: white;
    }
    h1 {
      font-size: 50px;
      position: relative;
      text-transform: uppercase;
      padding-bottom: 20px;
      font-weight: 600;
      &::after {
        content: "";
        width: calc(100% + 50px);
        left: -50px;
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: #eecf3b;
      }
      @media (max-width: 1200px) {
        font-size: 46px;
      }
      @media (max-width: 800px) {
        font-size: 38px;
        line-height: 44px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 300;
      @media (max-width: 1200px) {
        font-size: 18px;
      }
      @media (max-width: 800px) {
        font-size: 16px;
      }
    }

    .btn-wrap {
      display: flex;
      gap: 50px;
      a {
        color: white;
        border: none !important;
        width: max-content;
        padding: 10px 20px;
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        background-color: #eecf3b;
        @media (max-width: 1200px) {
          font-size: 16px;
        }
        @media (max-width: 800px) {
          font-size: 14px;
        }
        @media (max-width: 550px) {
          padding-bottom: 10px;
          border-radius: 5px;
        }
      }
      .next {
        position: relative;
        color: white;
        background-color: transparent;
        &::after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: white transparent transparent transparent;
          position: absolute;
          bottom: 0;
          content: "";
          left: 50%;
          transform: translate(-50%);
        }
        @media (max-width: 550px) {
          display: none;
        }
      }
      .to-ticket {
        background-color: #eecf3b;
        color: black;
        padding-right: 50px;
        position: relative;
        border-radius: 6px;
        font-size: 16px;
        cursor: pointer;
        &::before {
          background-image: url(../../img/graphic_assets/arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 20px;
          height: 20px;
          border: none;
          content: "";
          position: absolute;
          transform: scale(-1, -1) translateY(50%);
          top: 50%;
          right: 15px;
        }
      }
    }
  }
}
