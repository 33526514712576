footer {
  .footer__nav {
    .menu-items-hover {
      padding-left: 40px;

      &:hover {
        a {
          color: #eecf3b;
          opacity: 1;
        }
      }
      a {
        padding-left: 0;
        position: relative;
      }
    }
  }
  .footer__bg-container {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .footer__waves {
      position: absolute;
      background-image: url(../../img/graphic_assets/decor-lines-A.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 600px;
      height: 400px;
      transform: rotate(45deg);
      z-index: 0;
      &.wave--left {
        bottom: -50px;
        right: unset;
        left: -340px;
      }

      &.wave--right {
        top: -50px;
        left: unset;
        right: -340px;
      }
    }
  }

  // &#footer::before{
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     top: -100px;
  //     right: -100px;
  //     width: 300px;
  //     height: 300px;
  //     background-image: url(../../img/graphic_assets/decor-lines-A.svg);
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     background-size: cover;
  //     transform: rotate(45deg);
  // }

  // &#footer::after{
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     bottom: -100px;
  //     left: -100px;
  //     width: 300px;
  //     height: 300px;
  //     background-image: url(../../img/graphic_assets/decor-lines-A.svg);
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     background-size: cover;
  //     transform: rotate(45deg);
  // }

  // @include mqTablet{

  //     &#footer::before{
  //         width: 500px;
  //         height: 500px;
  //         top: -150px;
  //         right: -150px;
  //     }

  //     &#footer::after{
  //         width: 500px;
  //         height: 500px;
  //         bottom: -150px;
  //         left: -150px;

  //     }
  // }

  // @include mqLargeScreen{
  //     &#footer::before{
  //         left: unset;
  //         width: 350px;
  //         height: 350px;
  //         top: -100px;
  //         right: -140px;
  //     }

  //     &#footer::after{
  //         width: 350px;
  //         height: 350px;
  //         bottom: -80px;
  //         left: -150px;
  //         top: unset;
  //         right: unset;
  //     }
  // }

  padding: 5em 0 !important;

  @include mqTablet {
    padding: 3.7em 0 !important;
  }
  p a,
  span a,
  label a {
    text-decoration: none;
  }
  border-top: 4px solid color(primary);

  .list-inline {
    li {
      display: inline-block !important;
    }
  }

  .list--hover {
    opacity: 1;
  }

  .footer__logo__wrapper {
    justify-content: center;
    position: relative;
    height: 100%;

    .logo {
      object-fit: contain;
      text-align: center;
      margin: 0 auto;
      width: 200px;
      height: auto;
      display: block;
      max-height: unset;

      @include mqTablet {
        margin: 0;
        vertical-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .footer__desc {
    line-height: 160%;
    padding-top: 1em;
    opacity: 0.6 !important;

    @include mqTablet {
      padding-top: 0;
    }
  }

  .footer__nav {
    li {
      line-height: 1.85714286em !important;
      display: block;
      &.menu-items-hover {
        line-height: 1.85714286em !important;
      }
      a:hover {
        color: color(primary) !important;
      }
    }

    &.last-col {
      margin-bottom: 0.5em;
    }
  }

  .footer__contact {
    padding-top: 2em;

    @include mqSmallScreen {
      padding-top: 0;
    }
  }
  .credits-area {
    padding-left: 60px;
    * {
      font-weight: 400;
    }
    @media (max-width: 768px) {
      padding-left: 50px;
    }
    .col-12 {
      padding-left: 0;
    }
    .credits-area__wrapper {
      a {
        display: inline-block;
      }
    }
  }
  .szallas-logo {
    padding-left: 10px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
  .list-contact {
    * {
      color: white;
      font-weight: 400;
    }
    a:not(:last-child) {
      margin-right: 0.25em;
    }

    .contact-icon {
      font-size: 1.1em !important;

      border: 1px solid white;
    }
  }

  .left-padding {
    padding-left: 27px;
  }

  .hr-st-margin {
    margin: 0.6em 0;
  }

  .fb-icon-wrapper {
    .fb-icon {
      display: none;
    }
  }
  li.social {
    display: flex;
    gap: 10px;
    align-items: center;

    span {
    }
    img {
      width: 22px;
      height: 22px;

      color: white;
    }
  }

  .title-wrap {
    padding: 0 20px;
    .footer-col-titles {
      font-weight: 400;
      // opacity: .4;
      margin-bottom: 0;
      font-size: 18px;
      position: relative;
      padding-left: 20px;
      width: min-content;
      &::before {
        content: "";
        height: 6px;
        width: 6px;
        background-color: #eecf3b;
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        top: 13px;
      }
    }
  }

  .pdf-hover-footer {
    &:hover {
      opacity: 1;
    }
  }

  .hover-darker {
    &:hover {
      opacity: 0.7;
    }
  }
}
