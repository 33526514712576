// General section adjustments

[class*="--section"] {
  padding-top: 3em !important;
  padding-bottom: 3em;
  position: relative;

  &.firework--section {
    padding-top: 0 !important;
  }
}

[class*="video--section"] {
  padding-top: 0em !important;
  padding-bottom: 3em;
  position: relative;
}

.section {
  font-size: $textsize-body;

  &.section--fullwidth {
    width: 100%;

    .container--fullwidth {
      max-width: unset;
      width: 100%;
    }

    .container--xxl {
      max-width: 1680px;
      width: 100%;
    }
  }

  .section__titlebar {
    width: 100%;
    margin-bottom: $padding-big;

    &.titlebar--center {
      text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0 auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
    }

    p {
      max-width: 600px;
      margin-top: $padding-small;
    }
  }

  .section__image-block {
    padding-bottom: $padding-small;
    img {
      width: 100%;
    }
  }

  .section__text-block {
    padding-bottom: $padding-small;

    .text-block__body-text {
      column-count: 1;
      column-fill: balance;
      column-gap: $padding-medium;

      &.text--2col {
        column-count: 2;
      }

      &.text--md-2col {
        @include mqTablet {
          column-count: 2;
        }
      }

      &.text--lg-2col {
        @include mqSmallScreen {
          column-count: 2;
        }
      }

      .p-with-quote {
        display: block;
        margin-bottom: 24px;
        p {
          margin-bottom: 8px;
        }

        .p--quote {
          font-size: inherit;
          line-height: inherit;
          font-family: inherit;
          font-style: italic;
        }
      }

      blockquote {
        font-size: inherit;
        line-height: inherit;
        font-family: "Open Sans";
      }
    }

    p,
    .text-bock__paragraph {
      font-size: 16px;
      line-height: 1.8em;
    }

    .text-block__title {
      line-height: normal;
      font-size: $textsize-title-medium;
      font-weight: 700;
      margin-top: $padding-smaller;
      margin-bottom: $padding-smaller;

      &--big {
        line-height: normal;
        font-size: $textsize-title;
        font-weight: 700;
        margin-top: $padding-smaller;
        margin-bottom: $padding-smaller;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.section-content__wrapper {
  align-items: center;

  .section-content__text {
    &:not(:last-of-type) {
      padding-bottom: 1em;
    }

    @include mqSmallScreen {
      &:not(:last-child) {
        padding-bottom: 0;
      }
    }
  }

  .section-content__title {
    font-size: $textsize-title;
    font-weight: bold;
  }

  .section-content__bodytext {
    > p {
      font-size: $textsize-body;
    }
  }

  .section-content__pic {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 360px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
      height: 100%;
      // width: auto;
      // height: 100%;
    }

    &.pic--center {
      > img {
        object-position: center;
      }
    }

    &.pic--bottom {
      > img {
        object-position: bottom;
      }
    }

    &.pic--top {
      > img {
        object-position: top;
      }
    }

    // Tortenelem.html
  }
}

// Section overlays
.section-overlay {
  display: block;
  min-height: 1px;
  width: 100%;
  padding: 0 12px;

  @include mqSmallScreen {
    padding: 0;
  }

  &.overlay--centered {
    z-index: 8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 728px;

    h2 {
      font-size: 32px;
      padding: 0;
      margin: 0;
    }

    h2,
    p {
      text-align: center;
    }
  }
}

.section-filter {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.65);
    z-index: 1;
  }
}

.section-image {
  width: 100%;
  height: auto;
  position: relative;
}

.section-image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section-image::after {
  content: "";
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .image-side {
    min-height: 100vh;
  }
}

// Anniversary 175 section
.section--anniversary {
  padding-bottom: 32px;

  .anniversary__image-container {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: -30px;

    @include mqMobileBig {
      margin-top: -40px;
    }

    @include mqTablet {
      margin-top: -45px;
    }

    @include mqSmallScreen {
      margin-top: -55px;
    }

    > img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;
      max-width: 650px;
    }
  }

  .anniversary__text-container {
    padding-top: 22px;
    text-align: center;

    > .anniversary__title {
      max-width: 600px;
      margin-bottom: 12px;
      margin: 0 auto;
    }
  }
}

// Notification Bar
.notification--section {
  background-color: color(primary);
  color: color(dark);
  padding: 0;
  z-index: 10;

  .notification__content {
    padding: 24px;

    .notification__title {
      margin: 0;
      font-weight: bold;
      color: color(dark);
      font-size: $textsize-title-medium;
    }

    .notification__bodytext {
      a,
      p {
        color: color(dark);
      }
      p {
        font-size: $textsize-body;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.info-bar {
  &--tickets-section {
    padding: 8px 15px;
    background-color: color(primary);
    width: 100%;
    text-align: center;

    > span {
      font-size: $textsize-compact;
      text-align: center;
      font-weight: 400;
      color: color(black);
      display: inline-block;
      line-height: normal;
    }
  }
}

.notification--section .notification-wrapper {
  padding: 15px;
  border: 2px solid #212023;
  background-color: color(primary);
}

.notification--section h3 {
  font-size: 2em;
  font-weight: 800;
  margin-bottom: 5px;
  color: black !important;
}

.notification--section .notification-body-text {
  font-size: 1.3em;
  color: black !important;
}

.gallery-section {
  padding-top: 3em;
}
// Map Section

.main-event-page-standards {
  padding: 8px;

  &.text-container {
    max-width: 550px;
  }

  &.form-container {
    max-width: 600px;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-width: 0.25em;
  }
}

.fire-wrapper {
  flex-direction: row;
  display: flex;
}
.blurred {
  filter: blur(2px);
}
.season-end {
  font-size: 18px;

  @media (min-width: 800px) {
    font-size: 22px;
    padding: 0 50px;
  }
}
