#transaction-page {

  .transaction-container{
    margin-top: 120px;
    margin-bottom: 84px;

    @include size(min, tablet, xxxmedium){
      margin-top: 220px;
      margin-bottom: 120px;
    }

    .transaction-page__download-buttons-container{
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include size(min, tablet, medium){
        flex-direction: row;
        column-gap: 12px;
      }

      .transaction-page__download-button{
        width: 100%;
      }
    }
    
  }
}
.dwn_buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reserve-btn-wrapper {
  display: flex;
  justify-content: space-around;
  button {
    padding : 0 20px;
  }
}
.back-to-ticket-btn {
  border: 1px solid black;
  text-align: center;
  transition: 0.2s;
  border-radius: 6px;
  padding : 5px ;
  margin-top: 20px;
  font-size: 0.85em;
  font-weight: bold;
  color: #212023;
  cursor: pointer;
  &:hover {
    background: #f0d039;
  }
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding : 5px;
}
.checkout-wrapper {
  font-size: 1.5em;
  display: flex;
  width: 100%;
  justify-content: end;
  padding-right: 30%;
  @media( max-width : 1200px) {
    padding-right: 20%;
  }
  @media( max-width : 990px) {
    justify-content: center;
    padding: 0 0 50px 0;

  }
}