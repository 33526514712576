// Content reveal
.content-toggle-section {
    .content-reveal__btn {
        margin-top: 50px;
    }

    .hidden-content {
        display: none;
        margin-top: -25px;
        transition: all 0.3s ease;

        &.show {
            display: unset;
            margin-top: 0;
        }
    }
}