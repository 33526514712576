.intro-wrapper {
  padding-top: 150px;

  background-color: #f2f2f1;
  @media (max-width: 950px) {
    padding-top: 0;
  }
  .flex-wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: minmax(200px, auto);
    background-color: #f2f2f1;
    padding-bottom: 150px;

    @media (max-width: 950px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
    }
    .text-wrap {
      padding-left: 50px;
      margin-right: 50px;
      overflow: hidden;
      @media (max-width: 650px) {
        margin-right: 20px;
        padding-left: 20px;
      }
      .contact-list {
        margin-bottom: 20px;
        li {
          font-weight: 500;
          position: relative;
          padding-left: 25px;
          font-size: 16px;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(45deg);
            background-color: #eecf3b;
            width: 10px;
            height: 10px;
          }
        }
      }
      .boxed__title {
        max-width: 650px;

        font-size: 50px;
        line-height: 50px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 10px;
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: calc(100% + 500px);
          background-color: black;
          bottom: 0;
          left: -0px;
          @media (max-width: 950px) {
            left: -50px;
          }
        }
        @media (max-width: 1200px) {
          line-height: 52px;
          font-size: 46px;
        }
        @media (max-width: 800px) {
          font-size: 38px;
          line-height: 44px;
        }
        @media (max-width: 600px) {
          font-size: 30px;
          line-height: 38px;
        }
      }
      .boxed__bodytext {
        display: flex;
        column-gap: 50px;

        @media (max-width: 1280px) {
          flex-direction: column;
          max-width: 500px;
        }
        .wrap {
          flex-basis: 60%;
          padding-bottom: 20px;

          .to-ticket {
            background-color: #eecf3b;
            color: black;
            padding: 10px 20px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
            padding-right: 50px;
            cursor: pointer;
            border-radius: 6px;
            position: relative;
            &::before {
              background-image: url(../../img/graphic_assets/arrow.svg);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 20px;
              height: 20px;
              border: none;
              content: "";
              position: absolute;
              transform: scale(-1, -1) translateY(50%);
              top: 50%;
              right: 15px;
            }
            &.drink {
              span {
                font-weight: 400;
                display: inline-block;
              }
            }
          }
        }
      }
      .wrap p,
      p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
        @media (max-width: 1200px) {
          line-height: 26px;
          font-size: 16px;
        }

        &.large {
          font-size: 20px;
          line-height: 26px;
          flex-basis: 50%;
          @media (max-width: 1200px) {
            font-size: 18px;
            line-height: 24px;
          }
          @media (max-width: 800px) {
            font-size: 16px;
            line-height: 26px;
          }
        }
        &.long {
          flex-basis: 50%;
        }
      }
      .perks {
        display: flex;
        column-gap: 10px;
        row-gap: 5px;
        margin: -30px 0 20px;
        flex-wrap: wrap;
        @media (max-width: 600px) {
          margin: -10px 0 20px;
        }
        .perk {
          display: inline-flex;
          align-items: center;
          span {
            padding: 2px 10px;
            padding-left: 15px;
            background-color: #eecf3b;
            margin-left: -15px;
            position: relative;
            z-index: 1;
            font-size: 14px;
            font-weight: 400;
            border-radius: 12px;
          }
          .img-wrap {
            width: 50px;
            height: 50px;

            border-radius: 50%;
            background-color: #eecf3b;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            img {
              width: 80%;
              position: relative;
              height: 80%;
              z-index: 2;
              padding: 5px;
              &.big {
                width: 90%;
                height: 90%;
              }
            }
          }
        }
      }
    }
    .boxed__pic__modal {
      padding-left: 50px;

      height: 400px;
      * {
        height: 100%;
      }
      @media (max-width: 950px) {
        padding: 50px !important;
        height: 400px;
      }
      @media (max-width: 650px) {
        padding: 20px !important;
        &.first {
          padding-top: 50px !important;
          height: 400px;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        &.left {
          object-position: 30%;
        }
      }
      &.abs {
        img {
          object-position: right;
        }
      }
    }
    &.rev {
      grid-template-columns: 60% 40%;
      @media (max-width: 850px) {
        grid-template-columns: 1fr;
      }
      .text-wrap {
        grid-column: 1/2;
        grid-row: 1/2;
        @media (max-width: 850px) {
          grid-column: 2/3;
          grid-row: 1/2;
        }
        .boxed__title {
          &::after {
            left: -50px;
          }
        }
      }
      .boxed__pic__modal {
        grid-column: 2/3;
        padding-right: 50px;
        padding-left: 0;
      }
    }
    // &:first-of-type {
    //   padding-bottom: 50px;
    // }
  }
  // .newyears-wrap {
  //   background-image: url(../../img/graphic_assets/fireworks_medium_blur.webp);
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  //   padding: 50px;
  //   margin-bottom: 100px;
  //   @media (max-width: 950px) {
  //     margin-bottom: 50px;
  //   }
  //   @media (max-width: 500px) {
  //     padding: 50px 20px;
  //   }
  //   .ny-inner {
  //     background: white;
  //     display: flex;
  //     padding: 20px;
  //     padding-right: 30px;
  //     padding-left: 15px;
  //     border-radius: 16px;
  //     width: fit-content;
  //     margin: auto;
  //     align-items: center;
  //     column-gap: 20px;
  //     row-gap: 10px;
  //     flex-wrap: wrap;
  //     justify-content: center;
  //     @media (max-width: 800px) {
  //       padding-left: 20px;
  //       padding-right: 20px;
  //     }
  //     .text {
  //       margin-bottom: 10px;
  //       h4 {
  //         margin-bottom: 10px;
  //         line-height: 1.1;
  //       }
  //     }
  //     @media (max-width: 700px) {
  //       flex-direction: column;
  //       .text {
  //         text-align: center;
  //       }
  //     }
  //     img {
  //       width: 70px;
  //       height: 70px;
  //     }
  //     .to-ticket {
  //       background-image: url(../../img/graphic_assets/fireworks_medium_blur.webp);
  //       background-repeat: no-repeat;
  //       background-position: center;
  //       background-size: 100vw;
  //       color: white;
  //       padding: 10px 20px;
  //       font-size: 16px;
  //       text-transform: uppercase;
  //       font-weight: 400;

  //       cursor: pointer;
  //       border-radius: 6px;
  //       position: relative;
  //     }
  //   }
  // }
}
