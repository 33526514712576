
.fillimage {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.fillimage img {
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 100%;
}

.image-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
    position: relative;
}

.image-side img {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
}


@media (min-width: 768px) {
    .section-image::after {
        display: block;
    }  
}
