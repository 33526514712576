.loginWrapper {
  min-height: 700px;
  display: flex;
  justify-content : center;
  align-items : center;
}
.login {

  .pass {
    margin-bottom: 40px;
    position: relative;
    .passShow {
      position: absolute;
      bottom : 15px;
      right: 5px;
      width:25px;
      height:15px;
      cursor: pointer;
    }
  }
}