// Boxed contents

.bg--maincol,
.boxed.bg--maincol,
.boxed.boxed--border.bg-maincol {
  background-color: color(primary);
}

.bg--secondarycol,
.boxed.bg--secondarycol,
.boxed.boxed--border.bg-secondarycol {
  background-color: color(dark);
}

.boxed {
  padding: $padding-big;
  &.boxed-home-page-form {
    padding: 46px;
  }
  &.boxed--compact {
    padding: $padding-small;
    border-radius: 2px;
  }

  &.boxed--shadow {
    -webkit-box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
    box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
  }

  &.boxed--border {
    &.border--main {
      border: 1px solid color(primary);
    }
    &.border--secondary {
      border: 1px solid color(dark-accent);
    }

    &.border--light {
      border: 1px solid rgba($color: color(dark-accent), $alpha: 0.2) !important;
    }
  }

  .boxed__text {
    align-self: center;
  }

  .boxed__title {
    font-size: 32px;
    font-weight: 800;
    color: color(dark);
    line-height: 120%;

    &[class*="--medium"] {
      font-size: $textsize-title-medium;
    }
  }

  .boxed__bodytext {
    line-height: 160%;

    &:not(:last-of-type) {
      margin-bottom: $padding-big;
    }

    > p {
      margin-bottom: 1em;
      line-height: inherit;
    }
  }

  .boxed__pic-container {
    align-self: stretch;

    .boxed__pic {
      height: 100%;
      width: 100%;
      display: block;
      position: relative;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .boxed__pic__modal {
      height: 100%;
      width: 100%;
      display: block;
      position: relative;

      &.center-align-home-form {
        align-items: center;
        display: flex;
      }
      > div {
        height: 100%;
        > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .btn {
    margin-top: 12px;
  }
}

.boxed--style1 {
  padding: 0;
  background-color: white;

  .box-header {
    text-align: center;
    display: block;
    width: 100%;
    color: white;
    border-bottom: 5px solid color(primary);
    padding: 1em 2.5em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 !important;
      margin: 0 !important;
      color: color(primary);
    }

    h3 {
      font-weight: 600;
    }

    .header-content {
      padding: 1em 2.5em;
    }

    .box-content-section {
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      }

      div {
        padding-right: 25px;
        padding-left: 25px;
      }

      .box-section-header {
        font-size: 1.65em;
        background-color: rgba(0, 0, 0, 0.045);
        padding-top: 0.65em;
        padding-bottom: 0.65em;
      }
    }
  }

  .box-header--copy2 {
    text-align: center;
    display: block;
    width: 100%;
    color: white;
    border-bottom: 5px solid color(primary);
    padding: 0.5em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 !important;
      margin: 0 !important;
      color: color(primary);
    }

    h3 {
      font-weight: 200;
    }

    .header-content {
      padding: 1em 2.5em;
    }

    .box-content-section {
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      }

      div {
        padding-right: 25px;
        padding-left: 25px;
      }

      .box-section-header {
        font-size: 1.65em;
        background-color: rgba(0, 0, 0, 0.045);
        padding-top: 0.65em;
        padding-bottom: 0.65em;
      }
    }
  }

  .pd-bottom {
    padding-bottom: 20px;
  }
  .box-header--copy {
    text-align: center;
    display: block;
    width: 100%;
    color: white;
    border-bottom: 5px solid color(primary);
    padding: 1em 2em;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 !important;
      margin: 0 !important;
      color: color(primary);
    }

    h3 {
      font-weight: 600;
    }

    .header-content {
      padding: 1em 2.5em;
    }

    .box-content-section {
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      }

      div {
        padding-right: 25px;
        padding-left: 25px;
      }

      .box-section-header {
        font-size: 1.65em;
        background-color: rgba(0, 0, 0, 0.045);
        padding-top: 0.65em;
        padding-bottom: 0.65em;
      }
    }
  }

  .boxed-content {
    padding: 25px;

    &.no-top-bottom {
      padding: 0 25px 0px 25px;
      &.no-sides-padding {
        padding: 0;
      }
    }

    &.no-bottom {
      padding: 25px 25px 0 25px;
    }

    @include mqLargeScreen {
      padding: 32px;

      &.no-top-bottom {
        padding: 0 32px 0 32px;
        &.no-sides-padding {
          padding: 0;
        }
      }

      &.no-bottom {
        padding: 32px 32px 0 32px;
      }
    }
  }
}

.boxed--style-info {
  margin: 25px 0 25px 0 !important;
  background-color: color(primary) !important;
  min-height: 1px;
  padding: 10px;
  line-height: 20px;
  font-size: 13px;
  border: 1px solid color(dark);
  font-weight: 500;
  .info-text-wrapper {
    margin-right: 20px;
    line-height: 24px;
  }
  .info-section {
    margin: auto 0 !important;
    padding: 0 20px !important;
  }
  .style-info-wrapper {
    display: flex;
  }
  i {
    color: color(dark);
  }
  span {
    color: color(dark);
  }
}

.max-width-test {
  width: 30%;
  @include mqTablet {
    width: 50%;
  }
  &.ship-types {
    width: 100%;
    margin: 35px 0;
  }
}

.left-radius-test {
  border-top-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.mondSunCont {
  background-color: rgba(231, 231, 230, 0.45);
}
.right-radius-and-right-border {
  width: 70%;
  border-right: 1px solid #ececec;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;

  @include mqTablet {
    width: 50%;
  }

  &.mondSunWrapper {
    background-color: rgba(231, 231, 230, 0.45);
    width: 100%;
    border-radius: 0;
    @include mqTablet {
      width: 100%;
    }
  }
}

.center-row-right-border {
  border-right: 1px solid #ececec;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.both-radius-test {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  overflow: unset;
}

.first-row {
  height: 100px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mqMobileSmall {
    height: 100px !important;
    padding: 0 10px !important;
  }
}

.all-row {
  height: 100px !important;

  img {
    align-items: flex-end;
  }

  @include mqMobileSmall {
    height: 80px !important;
  }
}

.img-text-wrapper {
  display: flex;
  max-height: 100px;
}
