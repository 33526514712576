.container {
  position: relative;
}

@media (min-width: 1640px) {
  .container-xl {
    width: 100%;
    max-width: 1400px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .container {
    max-width: 800px !important;
  }
}
