a {
  color: color(primary);

  &.white {
    color: color(light);
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.textcol-main {
  color: color(primary) !important;
}
.textcol-dark {
  color: color(dark-accent) !important;
}
.textcol-darker {
  color: color(dark) !important;
}

.text--maincol,
span.text--maincol strong {
  color: color(primary) !important;
}

// TEXT SIZES
.text-xs,
p.text-xs {
  font-size: 0.8em !important;
}

.type--fine-print {
  line-height: 1.8em;
}

.switchable__text h2 {
  font-size: 3em;
}

.textsize-small {
  font-size: 0.6em;
  line-height: 0.8em;
}
.textsize-medium {
  font-size: 0.85em;
  line-height: 1.8em;
}
.textsize-normal {
  font-size: 1em;
  line-height: 1.6em;
}
.textsize-big {
  font-size: 1.2em;
  line-height: 1.8em;
}
.textsize-bigger {
  font-size: 1.4em;
  line-height: 2em;
}
.textsize-huge {
  font-size: 2em;
  line-height: 2.2em;
}

h1 {
  font-size: $textsize-title;
  font-weight: bold;
}
h2 {
  font-size: $textsize-title-medium;
}

// TEXT WEIGHTS
strong {
  font-weight: 800;
}

// TEXT DECORATIONS
[class*="decorline-"] {
  position: relative;
  display: inline-block !important;
  padding-bottom: 12px;
  width: 100%;
}

[class*="decorline-"]::after {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
}

.decorline-main::after {
  width: 40px;
  height: 6px;
  background-color: color(primary);
}

.decorline-thin::after {
  width: 100%;
  height: 1px;
  background-color: #252525;
}

[class*="decorline-"] > h1,
[class*="decorline-"] > h2,
[class*="decorline-"] > h3,
[class*="decorline-"] > h4,
[class*="decorline-"] > h5,
[class*="decorline-"] > h6,
[class*="decorline-"] > p {
  padding-bottom: inherit !important;
}

.decorline-main-small::after {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 6px;
}

// TEXT BREAKERS
[class*="break-"] {
  display: inline;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

.break--mobile {
  display: block;
}

[class*="break-tablet"] {
  @include mqTablet {
    display: block;

    &[class*="-nobreak"] {
      display: initial;
    }
  }
}

[class*="break-smallscreen"] {
  @include mqSmallScreen {
    display: block;

    &[class*="-nobreak"] {
      display: initial;
    }
  }
}

[class*="break-mediumscreen"] {
  @include mqMediumScreen {
    display: block;

    &[class*="-nobreak"] {
      display: initial;
    }
  }
}

[class*="break-largescreen"] {
  @include mqLargeScreen {
    display: block;

    &[class*="-nobreak"] {
      display: initial;
    }
  }
}

/*-----------------------------------
            05 - TEXT FORMATS
-----------------------------------*/

/*COMMENTS*/
.comment--light {
  display: block;
  padding: 0;
  margin: 12px 0;
  padding: 0;
  position: relative;
  width: 100%;

  > p {
    display: inline;
    padding-left: 6px;
    vertical-align: middle;
  }

  &::before {
    content: none;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    display: inline-block;
  }

  &.centered {
    text-align: center;

    > p {
      text-align: center;
    }
  }

  &.comment--warning {
    &::before {
      content: url(../img/icons/warn-full-yellow.svg);
    }
  }
}

.temathic-subtitle {
  font-size: 30px;
  font-weight: 700;
  color: color(dark);
}

.temathic-content-wrapper {
  padding-bottom: 15px;
}

.temathic-content-wrapper-more {
  padding-top: 15px;
}
* {
  font-family: "Roboto Slab", serif !important;
  color: black;
  font-weight: 300;
}
