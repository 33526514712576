.route-wrap {
  background-color: white;
  padding: 100px 0;
  @media (max-width: 950px) {
    padding: 50px 0;
  }
  .inner-title {
    display: none;
  }
  @media (min-width: 1151px) {
    .title-wrap {
      &.mobile-title {
        display: none;
      }
      &.inner-title {
        display: block;
      }
    }
  }
  h3 {
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding-left: 50px;
    padding-right: 20px;
    padding-bottom: 10px;
    line-height: 50px;
    max-width: 800px;
    margin: 0;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 50px);
      background-color: black;
      bottom: 0;
      left: -50px;
    }
    @media (max-width: 1200px) {
      line-height: 52px;
      font-size: 46px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 44px;
      padding-left: 20px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 38px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
    }
  }
  .text-img-wrap {
    display: flex;
    padding: 0;
    align-items: center;
    &.title {
      flex-direction: row;
      .ship-route__text {
        width: 100%;
        flex-basis: 100%;
      }
      .large {
        padding-left: 50px;
        margin-top: 20px;
        @media (max-width: 800px) {
          padding: 0 20px;
        }
      }
    }
    @media (max-width: 1150px) {
      flex-direction: column-reverse;
      gap: 50px;
      .ship-route__map {
        max-width: 700px;
        margin: auto;
      }
    }
    .ship-route__text {
      flex-basis: 60%;
      p {
        margin-bottom: 20px;
        line-height: 24px;
        font-size: 16px;
        color: black;
        font-weight: 300;
        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 24px;
        }
        &.large {
          font-size: 18px;
          line-height: 26px;
          flex-basis: 50%;
          max-width: 600px;

          margin-bottom: 30px;
          @media (max-width: 1200px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      .text-inner-wrap {
        padding: 20px 50px 0;
        height: 100%;
        @media (max-width: 850px) {
          padding: 20px;
        }
        .list-wrapper {
          display: flex;

          justify-content: center;
          padding-top: 50px;
          @media (max-width: 850px) {
            padding-top: 0;
          }
        }
        .ship-route__list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 50px;

          @media (max-width: 1200px) {
            grid-template-columns: 1fr;
            gap: 10px;
          }
          @media (max-width: 800px) {
            padding-left: 20px;
          }
          > li {
            display: flex;
            width: 100%;
            gap: 20px;
            justify-content: flex-start;
            align-items: flex-start;

            > i {
              flex-shrink: 0;

              display: block;
              position: relative;
              top: -5px;
              width: 60px;
              height: 60px;
              @media (max-width: 950px) {
                width: 40px;
                height: 40px;
              }
              // @media (max-width: 600px) {
              //   width: 30px;
              //   height: 30px;
              // }

              &.route-point {
                &::after {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: block;
                  background-repeat: no-repeat;
                  background-size: contain;
                }

                &.coffee {
                  &::after {
                    background-image: url(../../img/graphic_assets/route-icon-coffee.svg);
                  }
                }

                &.photo {
                  &::after {
                    background-image: url(../../img/graphic_assets/route-icon-photo.svg);
                  }
                }

                &.wine {
                  &::after {
                    background-image: url(../../img/graphic_assets/route-icon-wine.svg);
                  }
                }

                &.gramophone {
                  &::after {
                    background-image: url(../../img/graphic_assets/route-icon-gramophone.svg);
                  }
                }
                &.binocular {
                  &::after {
                    background-image: url(../../img/graphic_assets/route/route-icon-binoculars.svg);
                  }
                }
              }
            }

            > p {
              // font-size: 14px;
              // color: color(dark);
              // display: block;
              width: 100%;
            }
          }
        }
      }
    }
    .ship-route__map {
      flex-basis: 40%;
      position: relative;

      .departures__img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
        img {
          max-height: 600px;
        }
      }
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 50px;
      //   background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      // }
    }
    .audioguide-wrap {
      border-radius: 18px;
      border: 1px solid #252525;
      display: flex;
      position: relative;
      max-width: 650px;
      margin: 20px 0;
      @media (max-width: 1200px) {
        margin: 50px auto;
      }
      @media (max-width: 700px) {
        flex-direction: column;
        max-width: 400px;
      }
      .text {
        padding: 20px 120px 20px 70px;
        overflow: hidden;

        .head-wrap {
          position: relative;
          .icon-wrap {
            position: absolute;
            left: -50px;
            top: 0;
          }
          h4 {
            margin-bottom: 0;
            line-height: 36px;
            b {
              font-size: 36px;
              display: block;
              max-width: 250px;
            }
            br {
              display: none;
            }
            span {
              display: block;
            }
          }
        }
        p {
          margin: 0;
        }
        @media (max-width: 700px) {
          padding: 20px;
          .head-wrap {
            h4 {
              font-size: 24px;
              line-height: 26px;
              margin-bottom: 20px;
              b,
              span {
                max-width: initial;
                font-size: 24px;
                text-align: center;
              }
              span {
                font-size: 18px;
              }
            }
            .icon-wrap {
              position: static;
              margin: 0 auto 10px;
            }
          }
          p {
            line-height: 28px;
            text-align: center;
          }
        }
      }
      .icon-wrap {
        width: 40px;
        height: 40px;
        background-color: #252525;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 950px) {
        }
        i {
          background-image: url(../../img/icons/headphones.svg);
          width: 90%;
          height: 90%;
          display: block;
        }
      }

      .flags {
        display: flex;
        gap: 7px;
        row-gap: 5px;
        flex-wrap: wrap;
        height: 100%;
        top: 0;
        flex-shrink: 0;
        width: 100px;
        overflow: hidden;
        position: absolute;
        justify-content: flex-end;
        right: 10px;
        .flag-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
          &:nth-child(even) {
            transform: translateY(5px);
          }
        }
        @media (max-width: 700px) {
          position: static;
          width: 100%;
          flex-wrap: nowrap;
          justify-content: center;
          margin-bottom: 20px;
          .flag-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
            &:nth-child(even) {
              transform: none;
            }
          }
        }
      }
    }
  }
}
