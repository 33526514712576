.valentines-home {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  overflow: hidden;
  background-color: #f13d5c;
  gap: 50px;
  max-height: 400px;
  align-items: center;
  strong {
    font-weight: 500;
    color: white;
  }
  @media (max-width: 800px) {
    gap: 20px;
  }
  @media (max-width: 700px) {
    display: grid;
    max-height: initial;
    grid-template-columns: 1fr;
    grid-template-rows: 350px auto;
  }

  @media (max-width: 500px) {
    grid-template-rows: 250px auto;
  }
  .img-wrap {
    width: 100%;
    height: 100%;
    max-height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .text-wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    height: 100%;
    padding: 50px;
    max-width: 500px;
    justify-self: center;
    * {
      margin: 0;
    }
    @media (max-width: 900px) {
      padding: 50px 20px;
    }
    @media (max-width: 700px) {
      padding: 0 20px 20px;
    }
    h4 {
      text-transform: uppercase;
      position: relative;
      color: white;
      z-index: 1;
    }
    p {
      font-weight: 300;
      color: white;
      position: relative;
      z-index: 1;
    }
    .button {
      white-space: nowrap;
      background-color: white;
      padding: 8px 16px;
      position: relative;
      z-index: 1;
      border-radius: 8px;
      font-weight: 300;
      width: max-content;
      cursor: pointer;
      box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
      margin-top: 10px;
    }
  }
  .icon-wrap {
    position: absolute;
    min-height: 50px;
    width: 40px;
    transform: scale(-2, 2);
    right: 60px;
    opacity: 0.5;
  }
  .icon {
    position: absolute;

    &.icon1 {
      right: 0;
      top: -5px;
      width: 15px;
      height: 15px;
    }
    &.icon2 {
      left: -5px;
      bottom: 20px;
      width: 25px;
      height: 25px;
      transform: rotate(20deg);
    }
    &.icon3 {
      right: -10px;
      bottom: -5px;
      width: 35px;
      height: 35px;
      transform: rotate(-30deg);
    }
  }
}
.valentines-modal {
  min-width: 300px;
  max-width: 500px;
  width: max-content;
  position: fixed;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, calc(1000px + 50%));
  transition: 1s;
  z-index: 10000;
  background-color: #f13d5c;

  padding: 10px 20px;
  padding-right: 30px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.5);
  * {
    font-weight: 300;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 20px 15px;
    max-width: 400px;
    z-index: 10000;
  }
  @media (max-width: 550px) {
    min-width: 0;
    width: 80%;
  }
  @media (max-width: 450px) {
    width: 95%;
  }
  .modal-text-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
    strong {
      color: white;
      font-weight: 500;
    }
    @media (max-width: 800px) {
      flex-direction: column;
      padding: 0;

      .text {
        position: relative;
        z-index: 5;
        margin-bottom: 10px;
      }
    }
    // .icon-wrap {
    //   position: relative;
    //   min-height: 50px;
    //   width: 40px;
    //   @media (max-width: 1100px) {
    //     width: 65px;
    //   }
    //   @media (max-width: 800px) {
    //     width: 40px;
    //   }
    // }
    .text {
      h3,
      p {
        text-align: center;
      }
      h3 {
        margin-bottom: 10px;
      }
    }
    .icon-wrap {
      width: 45px;
      height: 55px;
      padding: 5px;
      // background-color: #252a30;
      // border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media (max-width: 800px) {
        width: 70px;
        height: 70px;
        opacity: 0.6;
        position: absolute;
        top: 20px;
        z-index: 1;
      }
    }
    .icon {
      position: absolute;
      // top: 0;
      // left: 0;
      width: 50px;
      padding: 5px;
      // padding: 10px 20px;
      &.icon1 {
        right: 0;
        top: -5px;
        width: 25px;
        height: 25px;
      }
      &.icon2 {
        left: -5px;
        bottom: 20px;
        width: 30px;
        height: 30px;
        transform: rotate(20deg);
      }
      &.icon3 {
        right: -10px;
        bottom: -5px;
        width: 40px;
        height: 40px;
        transform: rotate(-30deg);
      }
      @media (max-width: 800px) {
        &.icon1 {
          right: 0;
          top: -5px;
          width: 35px;
          height: 35px;
        }
        &.icon2 {
          left: -5px;
          bottom: 20px;
          width: 40px;
          height: 40px;
          transform: rotate(20deg);
        }
        &.icon3 {
          right: -10px;
          bottom: -5px;
          width: 50px;
          height: 50px;
          transform: rotate(-30deg);
        }
      }
    }
    .button {
      white-space: nowrap;
      background-color: white;
      padding: 8px 16px;
      .btn__text {
        color: black;
      }
      cursor: pointer;
      border-radius: 8px;
    }
    .text {
      padding: 0 20px;
    }
    p {
      color: white;
      font-weight: 300;
      // text-transform: uppercase;
      // width: max-content;
      // text-align: center;
      min-width: 300px;
      @media (max-width: 800px) {
        text-align: center;
      }
      @media (max-width: 550px) {
        min-width: 0;
      }
    }
    h3 {
      font-weight: 400;
      text-transform: uppercase;
      color: white;
      @media (max-width: 800px) {
        margin-bottom: 10px;
        text-align: center;
      }
    }
    .iksz {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      z-index: 10;
      color: white;
      transform: rotate(45deg);
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: 800px) {
        right: 10px;
        top: 10px;
        width: 35px;
        height: 35px;
      }
    }
  }
  &.visible {
    transform: translate(-50%, 50%);
  }
}
