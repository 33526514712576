$breakpoints:(
    phone:(
        tiny: 300px,
        small: 320px,
        medium: 360px,
        xlarge: 385px,
        large: 414px,
        huge: 480px,
        giant: 550px,
        default: 320px
    ),
    
    tablet:(
        small: 600px,
        xsmall:638px,
        xxsmall: 681px,
        medium: 768px,
        xmedium: 865px,
        xxmedium: 890px,
        xxxmedium: 991px,
        large: 1024px,
        xlarge:1060px,
        default: 768px
    ),
    
    screen: (
        tiny:1150px,
        xsmall: 1200px,
        small: 1280px,
        medium: 1440px,
        large: 1600px,
        hd: 1920px,
        default: 1600px
    ),
);

@mixin size($rule, $device, $screen-size){   
    $brkpoint: map-get(map-get($breakpoints, $device), $screen-size);

    @if $rule == min{
        @media(min-width: $brkpoint){
            @content;
        }
    } @else if $rule == max{
        @media(max-width: $brkpoint){
            @content;
        }
    }
}