#drinks-page {
  iframe {
    min-height: initial;
    height: 1px;
    width: 1px;
  }
  .drinks-page-wrapper {
    padding-top: 70px;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 991px) {
      padding-top: 165px;
    }
    .pdf-viewer {
      width: 100%;
      height: 100%;
      min-height: 100vh;
    }
    .head-wrap {
      h1 {
        text-align: center;
        padding: 20px;
        margin: 25px auto 20px;
      }
      .btn-wrap {
        width: fit-content;
        margin: 0 auto 50px;
        img {
          width: 20px;
          height: 22px;
        }
        .btn {
          background-color: #eecf3b;
          color: black;
          padding: 10px 20px;
          padding-right: 20px;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 400;

          cursor: pointer;
          border-radius: 6px;
          position: relative;
          min-width: initial !important;
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}
