.faq-wrap {
  padding: 100px 0;
  background-color: #f2f2f1;
  @media (max-width: 950px) {
    padding: 50px 0;
  }
  .questions-wrap {
    max-height: 600px;
    overflow-y: scroll;
    padding: 30px 30px 30px 50px;
    scrollbar-width: thin;
  }
  h3 {
    font-size: 50px;
    line-height: 50px;
    padding: 0 20px 10px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    margin: auto;
    max-width: 800px;
    text-align: center;
    @media (max-width: 1200px) {
      line-height: 52px;
      font-size: 46px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 44px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .faq-inner-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 50px;
    padding-right: 50px;
    column-gap: 20px;
    max-width: 1600px;
    margin: 0 auto;
    @media (max-width: 1200px) {
      grid-template-columns: 60% 40%;
    }
    @media (max-width: 950px) {
      grid-template-columns: 1fr;
    }
  }
  .faq-item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.2);

    background-color: white;
    transition: 0.3s;

    cursor: pointer;
    border-radius: 5px;
    max-width: 500px;
    margin: 0 auto 20px;

    @media (max-width: 950px) {
      max-width: 600px;
    }
    .arrow-wrap {
      width: 50px;
      padding: 10px;
      border-left: 1px solid #252525;
      border-radius: 0 5px 5px 0;
      overflow: hidden;
      min-width: 50px;
      .arrow {
        height: 100%;
        width: 100%;
        object-fit: contain;
        transform: scale(-1, -1);
      }
    }
    .question-wrap {
      display: flex;
      justify-content: space-between;
      background-color: white;
      border-radius: 12px;

      .img-wrap {
        width: 50px;
        padding: 0 11px;
        border-left: 1px solid black;
        min-width: 50px;
      }
      img {
        width: 100%;
        height: 100%;
        transform: scale(-1, -1);
      }
      &.active {
        border-radius: 12px 12px 0 0;
        background-color: #252525;
        .img-wrap {
          border-left: 1px solid #eecf3b;
          border-radius: 0 12px 0 0;

          img {
            transform: rotate(-90deg);
          }
        }
      }
    }
    .question {
      line-height: 30px;
      font-size: 16px;
      margin: 0;
      font-weight: 300;
      padding: 10px 10px 10px 20px;
      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &.active {
      background-color: #252525;
      transform: translate(50px);
      border-radius: 5px 10px 10px 5px;
      color: white;

      .arrow-wrap {
        background-color: #eecf3b;
        border: none;
      }
    }
  }
  .answer-wrap {
    max-width: 500px;
    height: fit-content;
    align-self: center;
    justify-self: center;
    border-radius: 20px;
    padding: 50px;
    background-color: #252525;
    width: 100%;
    cursor: default;
    @media (max-width: 950px) {
      max-width: 600px;
    }
    p {
      color: white;
      margin: 0;
      line-height: 30px;
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      margin: 10px 0;
      &.long {
        margin-top: 20px;
      }
      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 24px;
        &.long {
          margin-top: 10px;
        }
      }
    }
    li {
      position: relative;

      font-size: 16px;
      text-transform: lowercase;
      list-style: none;
      padding-left: 25px;
      &::before {
        width: 5px;
        height: 5px;
        background-color: black;
        top: 10px;
        left: 10px;
        content: "";
        position: absolute;
      }
    }
  }
  &.mobile {
    .faq-inner-wrap {
      display: block;
      padding: 50px 50px 0 50px;
      @media (max-width: 650px) {
        padding: 50px 20px 0;
      }
      .questions-wrap {
        padding: 0;

        align-items: center;
        justify-content: center;
        height: initial;
        overflow: visible;

        max-height: none;

        .faq-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          background-color: transparent;
          border-radius: 10px;

          .question {
            border-radius: 10px;
            color: black;
            background-color: white;
            // font-weight: 400;
            &.active {
              background-color: #252525;
              color: white !important;
              font-weight: 600;
            }
          }
          &.active {
            transform: translate(0);

            .question {
              border-radius: 10px 10px 0 0;
            }
          }
          .answer-wrap {
            height: 0;
            background-color: white;
            overflow: hidden;
            padding: 0;
            border-radius: 0;
            width: 100%;
            p {
              color: black;
              height: 0;
            }
            &.active {
              height: initial;
              overflow: visible;
              padding: 10px 20px 20px;
              border-radius: 0 0 10px 10px;
              p {
                height: initial;
              }
            }
          }
        }
      }
    }
  }
}
