

#preloader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: color(dark);
    opacity: 1;
    overflow: hidden;
    z-index: 9999;

    transition: opacity 0.5s ease-in-out;

    &.loaded {
        opacity: 0;

    }

    &::before{
        content: "";
        display: block;
        position: absolute;
        top: -100px;
        right: -100px;
        width: 300px;
        height: 300px;
        background-image: url(../../img/graphic_assets/decor-lines-A.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(45deg);
    }

    &::after{
        content: "";
        display: block;
        position: absolute;
        bottom: -100px;
        left: -100px;
        width: 300px;
        height: 300px;
        background-image: url(../../img/graphic_assets/decor-lines-A.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(45deg);
    }

    @include mqTablet{      

        &::before{
            width: 500px;
            height: 500px;
            top: -150px;
            right: -150px;
        }

        &::after{
            width: 500px;
            height: 500px;
            bottom: -150px;
            left: -150px;
           
        }
    }

    @include mqLargeScreen{
        &::before{
            width: 600px;
            height: 600px;
            top: -180px;
            right: -180px;
        }

        &::after{
            width: 600px;
            height: 600px;
            bottom: -180px;
            left: -180px;
            top: unset;
            right:unset;
        }
    }

    .preloader__content-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        

        .preloader__logo {
            width: 300px;
            margin: 0 auto;
            > img {
                display: block;
                margin: 0 auto;
            }
        }

        .preloader__text {
            margin-top: $padding-tiny;
            color: color(light);
            text-align: center;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: inherit;
                text-transform: uppercase;
                letter-spacing: .2em;
            }
        }
    }
}

