.work-container{
    padding-top: 70px;
    padding-bottom: 70px;


    .work-title{
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    }

    p{
        font-size: 17px;
    font-weight: 400;
    }

    li{
        list-style: inside;

    }
    a{
    text-decoration: none;
    }

    .descricption-work{

        @media screen and (max-width: 990px) {
        margin-bottom: 40px;
    margin-top: 100px;

        }
        li{
list-style: none;
margin-top: 10px;
padding-top: 10px;
border-top: 1px solid #e5e5e5;
        }

.subtitle{
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: normal;
    position: relative;
    margin-bottom: 60px;

    &:after{
        content: "";
        position: absolute;
        width: 30px;
        border-bottom: 2px solid color(primary);
        margin-top: 10px;
        display: block;
    }
}
        .author,.date,.category{
            display: flex;
    flex-direction: column;

    strong{
        color:black;
        font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    }
    span{
    font-size: 16px;
    }
        }
    }
}