.border--main {
    border-color: color(primary);
}

.border--light {
    border-color: rgba(0, 0, 0, 0.12) !important;
}

.border-top-m {
    border-width: 0;
    border-top-width: 10px;
    border-style: solid;
}

.border-top-sm {
    border-width: 0;
    border-top-width: 1px;
    border-style: solid;
}

.border-bottom-m {
    border-width: 0;
    border-bottom-width: 10px;
    border-style: solid;
}

.border-right {
    border-width: 0;
    border-right-width: 10px;
    border-style: solid;
}

.border-top-bottom-m {
    border-width: 0;
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-style: solid;
}