html {
    scroll-behavior: smooth;
}

// Opacity
.opacity-medium {
    opacity: 0.4;
}

// Transformations
.flip-h {
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
    filter: FlipH;
    -ms-filter: "FlipH";
}

.flip-v {
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    -webkit-filter: FlipV;
    filter: FlipV;
    -ms-filter: "FlipV";
}