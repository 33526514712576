.form-container {
  @include mqLargeScreen {
    max-width: 600px;
    margin: 0 auto;
  }

  textarea:focus {
    border: color(black) 1px solid;
  }
}

// Email forms
.email-form {
  // Mui resets
  border: none !important;

  .MuiOutlinedInput-root {
    &.Mui-focused {
      fieldset {
        border: color(black) 1px solid;
        opacity: 1;
      }
    }
  }

  .MuiInputBase-root {
    font-family: "Open Sans";
    &.Mui-focused {
      fieldset,
      textarea {
        border: 1px black solid;
      }
    }
  }

  .email-form__button {
    font-family: "Open Sans";

    .MuiButton-label {
      font-family: "Open Sans";
    }
  }

  &.email-form--aside {
    padding: $padding-medium;
    margin: 0 auto;
    max-width: 600px;
  }

  &.email-form--simple {
    width: 100%;
    min-width: 280px;
    max-width: unset;

    &.home-page-form {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }

    .email-form__input {
      textarea {
        cursor: auto;
      }

      &.email-form__textarea {
        border-radius: 4px;
        padding: 12px;
        &::placeholder {
          color: rgba($color: color(dark), $alpha: 0.3);
          font-size: 17px;
        }
      }

      input {
        &::placeholder {
          color: rgba($color: color(black), $alpha: 0.65);
        }
      }
      .MuiInputBase-root {
        background-color: rgba($color: color(lighter), $alpha: 0.25);
      }
    }

    .email-form__button {
      padding: 16px 0 0 0;
    }
  }

  &.email-form--simple-dialog {
    width: 100%;

    max-width: unset;

    @include mqMobileBig {
      min-width: 280px;
    }

    @include mqMobileBig {
      min-width: 380px;
    }
    .email-form__input {
      &.email-form__textarea {
        border-radius: 4px;
        padding: 12px;
        &::placeholder {
          color: rgba($color: color(dark), $alpha: 0.3);
          font-size: 17px;
        }
      }

      input {
        &::placeholder {
          color: rgba($color: color(black), $alpha: 0.65);
        }
      }
      .MuiInputBase-root {
        background-color: rgba($color: color(lighter), $alpha: 0.25);
      }
    }

    .email-form__button {
      padding: 16px 0 0 0;
    }
  }

  .form__input-row {
    textarea {
      border-color: rgba($color: color(dark), $alpha: 0.25);

      &::placeholder {
        font-size: 16px;
      }

      &:active {
        border-color: black;
      }

      &:focus {
        border-color: black;
      }
    }
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  .email-form__input {
    .MuiFormLabel-root {
      &.Mui-focused {
        color: color(black);
      }

      &.Mui-error {
        color: red;
      }
    }

    .MuiInput-underline {
      &::after {
        border-color: color(black);
        border-width: 1px;
      }

      &:hover {
        &::before {
          border-width: 1px;
          border-color: rgba($color: color(black), $alpha: 0.5);
        }
      }
    }
  }

  textarea {
    padding: 16px 20px;
  }

  .email-form__button {
    padding: 10px 0 0 0;
  }
}

.phone--input {
  margin-top: 1.85714286em;
  width: 100%;

  .form-control {
    width: 100%;
  }

  .react-tel-input .flag-dropdown {
    background-color: white;
  }
}

.form--subtitle {
  margin-bottom: 0;
}

.form--title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0.4em;
}

.muiWrapper {
  .MuiFormControl-marginNormal {
    margin-top: 0.95714286em;
    margin-bottom: 0;
  }
}

.email--short {
  margin-bottom: 0;
}

.emailFormTitle {
  text-align: center;
  margin-bottom: 20px;
}

.Emailform-btn {
  padding: 1.85714286em 0px;
  width: 100%;
}

.emailFormLabel {
  margin-bottom: 0;
}

.timeTable {
  padding: 1.85714286em 0px;
  display: flex;
  justify-content: space-around;
}

.formWrapper {
  .MuiCardContent-root {
    padding: 35px;
  }
  @include size(min, screen, small) {
    width: 650px;
  }
  @include size(max, tablet, xxxmedium) {
    margin-top: 80px;
  }

  @include size(max, screen, small) {
    width: 100%;
  }
}

.subImg {
  padding-left: 0;
  padding-top: 40px;
}

.email-sender-section {
  .email-sender-section-wrapper {
    display: flex;
  }
}

.test {
  .sticky {
    padding-top: 120px;

    .muiWrapper {
      .MuiFormControl-marginNormal {
        margin-top: 5px;
        margin-bottom: 0;
      }
    }

    .timeTable {
      padding: 10px 0;
    }

    .phone--input {
      margin-top: 15px;
    }
  }
}

.homepage-form-padding {
  padding: 30px 0;
}
.fomr-title-home-page {
  font-weight: 600;
  color: white;
  margin-bottom: 0.14em;
}

.form-48hour {
  padding: 10px 20px;
  font-size: 1rem;
}
.contact-section-small {
  padding-top: 20px;
  h3 {
    font-size: 32px;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 0;
  }
  .contact-text {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 20px;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      display: block;
      margin: 20px 0;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .wrap {
    margin-bottom: 10px;
    .email,
    .phone {
      color: black;
      font-weight: 600;
      padding-left: 15px;
      border-left: 10px solid #eecf3b;
      display: block;
    }
    .working-hours {
      display: block;
    }
    &.line {
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        display: block;
        margin: 20px 0;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
