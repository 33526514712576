.popup {
  &.gyerek {
    position: fixed;
    left: 50%;

    background-color: white;
    top: initial;
    bottom: 20px;
    transform: translate(-50%, 0);
    z-index: 10;
    padding: 20px;
    max-width: 550px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 13px 3px rgba(0, 0, 0, 0.3);
    @media (max-width: 600px) {
      width: 90%;
      max-width: 320px;
      top: 50%;
      transform: translate(-50%, -50%);
      bottom: initial;
    }
    .close {
      top: 10px;
      right: 10px;
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 10px;
      line-height: initial;
    }
    p {
      margin-bottom: 5px;
      text-align: center;

      font-size: 16px;
      &.main {
        // width: max-content;
        position: relative;
      }
      &.desc {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 20px;
        font-style: italic;
        position: relative;
        text-align: left;
        &::before {
          content: "*";
          position: absolute;
          top: -2px;
          left: -5px;
        }
      }
    }
  }
}
