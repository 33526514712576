.aug20-wrapper {
  background-color: #fafafa;
  padding-bottom: 100px;
  @media (max-width: 650px) {
    padding-bottom: 50px;
  }
  h3 {
    font-weight: 600;
  }
  .nav--section {
    position: fixed;
    &.stcky {
      background-color: #252525;
      border-bottom: 5px solid #eecf3b;
    }
  }
  .to-ticket {
    background-color: #eecf3b;
    color: black !important;
    padding: 10px 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    padding-right: 50px;
    border-radius: 6px;
    position: relative;
    &::before {
      background-image: url(../../img/graphic_assets/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 20px;
      height: 20px;
      border: none;
      content: "";
      position: absolute;
      transform: scale(-1, -1) translateY(50%);
      top: 50%;
      right: 15px;
    }
  }
  .short {
    margin: 20px 0;
    opacity: 1;
  }
  .firework {
    color: white;
    position: absolute;
    z-index: 10;
    bottom: 100px;
    left: 5%;

    padding: 20px;
    @media (max-width: 1000px) {
      max-width: 600px;
    }
    @media (max-width: 600px) {
      bottom: 50px;
    }
    @media (max-width: 400px) {
      max-width: 100%;
      p {
        width: 100%;
      }
    }

    * {
      color: white;
    }
    p {
      font-size: 1.3rem;
      max-width: 80%;
    }
  }
  .aug20-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 200px;
    background-color: white;
    @media (max-width: 1200px) {
      margin: 100px 100px;
    }
    @media (max-width: 950px) {
      flex-direction: column;
      gap: 50px;
    }
    @media (max-width: 650px) {
      margin: 50px 50px;
    }
    @media (max-width: 500px) {
      margin: 50px 0 0 0;
    }

    &.section-1 {
      margin: auto;
      margin-top: 100px;
      padding: 50px;
      box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
      border-radius: 6px;
      max-width: 1200px;
      border: 1px solid #ececec;
      .aug20-gallery-wrap {
        .swiper-button-prev,
        .swiper-button-next {
          background-color: #eecf3b;
          opacity: 0.85;
          width: 50px;
          height: 50px;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);
          border-radius: 50%;
          cursor: pointer;
          &::after {
            content: "";
            width: 30px;
            height: 30px;
            background-image: url(../../img/graphic_assets/arrow.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          @media (max-width: 650px) {
            width: 35px;
            height: 35px;
            &::after {
              width: 25px;
              height: 25px;
            }
          }
        }
        .swiper-button-next {
          right: 10px;
          left: initial;
          &::after {
            transform: scale(-1, -1);
          }
        }
        .swiper-slide {
          height: 500px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      @media (max-width: 1400px) {
        margin: 100px 50px 0;
      }
      @media (max-width: 1100px) {
        .gallery-wrap {
          .swiper-slide {
            height: 400px;
          }
        }
      }
      @media (max-width: 950px) {
        .aug-20-section-pic-wrap {
          width: 100%;
          max-width: 500px;
          transform: scale(1.01, 1.01);
        }
        .gallery-wrap {
          .swiper-slide {
            height: 500px;
          }
        }
      }
      @media (max-width: 700px) {
        margin: 100px 20px 0;
        .gallery-wrap {
          .swiper-slide {
            height: 400px;
          }
        }
      }
      @media (max-width: 550px) {
        margin: 100px 0 0;
        .gallery-wrap {
          .swiper-slide {
            height: 400px;
          }
        }
      }
      @media (max-width: 500px) {
        padding: 50px 0 0;
      }
      ul {
        list-style: none;
        width: max-content;
        margin: auto;
        li {
          margin: 10px 0;
          font-weight: 600;
          text-align: left;
          span {
            font-weight: 300;
          }
        }
      }
      .aug-20-section-text {
        padding: 0 50px 0 0;
        h3 {
          margin: 0 0 20px 0;
        }
        ul {
          font-size: 18px;
        }
        @media (max-width: 950px) {
          padding: 0;
          text-align: center;
          .short {
            margin: 20px auto;
          }
        }
        @media (max-width: 650px) {
          padding: 0 50px;
        }
        @media (max-width: 550px) {
          padding: 0 20px;
        }
      }
    }
    &.section-2 {
      background: #181517;
      margin: 100px 0 0 0;
      padding: 50px 100px;
      @media (max-width: 850px) {
        padding: 50px 20px;
      }
      @media (max-width: 500px) {
        margin-top: 0;
      }
      .li,
      h3 {
        color: white;
        margin: 20px 0;
      }
      .ul {
        margin-top: 50px;
      }
      .li {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 50px;
        row-gap: 20px;
        margin: 10px 0 30px;
        &:last-of-type {
          margin-bottom: 0;
        }
        @media (max-width: 850px) {
          column-gap: 10px;
          margin: 10px 0 50px;
        }
        .includes {
          margin-bottom: 10px;
          padding-left: 15px;
        }
        ul {
          list-style: square;
          list-style-position: outside;
          padding-left: 30px;
          line-height: 24px;

          li {
            padding-left: 0;
            margin: 5px 0;
          }
        }
        .price {
          white-space: nowrap;
        }
        .desc-wrap {
          grid-column: 1/3;
        }
        .price-type {
          display: flex;
          gap: 10px;
          align-items: center;
          font-weight: 600;
          text-transform: uppercase;
          .price-letter {
            width: 30px;
            height: 30px;
            background: #eecf3b;
            color: black;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 450px) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .section {
        width: 100%;
      }
      .ticket-btn-wrapper {
        margin: 50px 0 20px;
      }
      .aug-20-section-pic-wrap {
        max-height: 800px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 800px;
        flex-basis: 50%;
        min-height: 800px;
      }
      .aug-20-section-text {
        flex-basis: 50%;
        max-width: 500px;
        * {
          color: white;
        }
        padding: 0 20px;
        @media (max-width: 1100px) {
          padding: 0;
        }
        @media (max-width: 950px) {
          padding: 0 50px;
        }
        @media (max-width: 500px) {
          padding: 0;
        }
        .aug20-drink {
          color: rgba(255, 255, 255, 0.7);
          .drink1 {
            margin: 50px 0 5px 0;
            font-size: 1rem;
            color: white;
          }
          .drink2 {
            margin: 5px 0;
          }
        }
      }
    }
    .section {
      // padding : 50px;
      width: 50%;
      @media (max-width: 950px) {
        width: 75%;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
    }
    .aug-20-section-pic-wrap {
      @media (max-width: 500px) {
        height: 100%;
        overflow: hidden;
      }
      img {
        width: 100%;
      }
    }
    &.flotta {
      max-width: 1200px;
      margin-bottom: 0;
      border-radius: 6px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 950px) {
        max-width: 650px;
      }
      @media (max-width: 800px) {
        max-width: 500px;
      }
      @media (max-width: 550px) {
        max-width: 380px;
      }
      .flotta-wrap {
        display: grid;
        border-radius: 6px;
        grid-template-columns: 2fr minmax(400px, 1fr);
        box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
        grid-template-rows: minmax(400px, 600px);
        @media (max-width: 950px) {
          grid-template-columns: 1fr;
          grid-template-rows: 350px auto;
        }
        @media (max-width: 550px) {
          grid-template-rows: 250px auto;
        }
      }
      .text-wrapper {
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
          line-height: 28px;
          font-size: 26px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
        }
        @media (max-width: 400px) {
          padding: 50px 20px;
        }
      }
      .img-wrap {
        border-radius: 6px 0 0 6px;
        overflow: hidden;
        height: 100%;

        @media (max-width: 950px) {
          border-radius: 6px 6px 0 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .ticket-btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    .ticket-btn {
      margin: 50px 0;
      width: 70%;
      &.last-btn {
        margin: 50px 0;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }
  .video-container {
    background-image: url(../../img/tuzi2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &::after {
      @media (max-width: 600px) {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
      }
    }
    .video {
      overflow: hidden;
      video {
        object-fit: cover;
        @media (max-width: 600px) {
          display: none;
        }
      }
    }
  }
}
.firework--section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  top: 0;
  left: 0;

  // &.sticky {
  //   background-color: transparent;
  //   position: fixed;
  //   bottom: 3%;
  //   left: 50%;
  //   transform: translate(-50%, 0);
  //   animation: floatIn 5s;
  // }

  .fire-wrapper {
    // width : 70%;
    // background-color: #eecf3b;
    background-image: url(../../img/tuzi2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 36px 3px rgba(125, 125, 125, 0.1);
    width: 66%;
    position: absolute;
    bottom: 50px;
    min-width: 800px;
    z-index: 25;
    border: 2px solid #eecf3b;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 20;
    }
    .close {
      right: 5px;
      top: 5px;
    }
    h3,
    p {
      color: white !important;
    }
    @media (max-width: 900px) {
      height: auto;
      padding: 20px;
      bottom: 100px;
      min-width: initial;
      width: 75%;
      max-width: 600px;
      .close {
        right: -10px;
        top: -10px;
      }
      .fireworkText {
        text-align: center !important;
      }
    }
    @media (max-width: 500px) {
      width: 100%;
      max-width: 380px;
      margin: 0 20px;
    }
    .to-ticket {
      background-color: #eecf3b;
      color: black !important;
      padding: 10px 20px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 400;
      cursor: pointer;
      padding-right: 50px;
      border-radius: 6px;
      position: relative;
      width: fit-content;
      &::before {
        background-image: url(../../img/graphic_assets/arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 20px;
        height: 20px;
        border: none;
        content: "";
        position: absolute;
        transform: scale(-1, -1) translateY(50%);
        top: 50%;
        right: 15px;
      }
    }
    .firework {
      height: 100%;
      // overflow: hidden;
      width: 100%;
      display: flex;
      position: relative;
      z-index: 21;
      .fire-content-wrap {
        display: flex;
        padding: 0 20px;

        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        width: 100%;
        @media (max-width: 900px) {
          flex-direction: column;
          align-items: center;
          padding: 0;
        }
      }
      .firework-icon-wrap {
        min-width: 100px;
        height: 100px;
        background-color: #181517;
        padding: 20px;
        img {
          height: 100%;
        }
        @media (max-width: 750px) {
          width: 100px;
          height: 100px;
          min-width: initial;
        }
      }
      .fire-text {
        padding: 20px 0;
        max-width: 65%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // gap: 10px;
        .firework-text {
          font-size: 16px;
        }
        @media (max-width: 750px) {
          max-width: 100%;
        }
      }
    }
    .firework-btn {
      display: flex;
      align-items: center;

      a {
        color: black;
        @media (max-width: 750px) {
          font-size: 14px;
        }
      }
    }
    .notification-btn {
      width: 200px;
      @media (max-width: 400px) {
        width: 175px;
      }
    }
    .close-btn {
      position: absolute;
      width: 30px;
      height: 30px;
      top: -10px;
      right: -25px;
      background-color: #181517;
      border-radius: 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 900px) {
        top: 5px;
        right: -10px;
      }
    }
  }
  &.static {
    .fire-wrapper {
      width: 100%;
      .firework {
        width: 100%;
        .fire-content-wrap {
          width: 100%;
        }
      }
    }
  }
}
@keyframes floatIn {
  0% {
    transform: translate(-50%, 5000px);
  }
  75% {
    transform: translate(-50%, 5000px);
  }
  100% {
    transform: translateY(-50%, 0px);
  }
}
