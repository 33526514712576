// Blur
.blur-me {
    filter: blur(5px);
     -webkit-filter: blur(5px);

    position: relative;

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        background-image: url(../img/graphic_assets/cross-stripes.png);
        background-size: 4px;
        background-repeat: repeat;

    }
}

// Shadows
.simple-shadow {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}