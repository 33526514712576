.btn {
  border: none !important;
}
.hero-section {
  &.hero--home {
    .hero__logo {
      margin: 0 auto $padding-small auto;
      margin-bottom: $padding-small;

      @include mqSmallScreen {
        margin-bottom: 0;
      }
    }

    .hero__text {
      .hero__title,
      .hero__subtitle {
        text-align: center;

        @include mqSmallScreen {
          text-align: left;
        }
      }
      hr {
        margin-left: auto;
        margin-right: auto;

        @include mqSmallScreen {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .btn.hero-CTA {
        display: block;
        margin: $padding-medium auto 0 auto;
        max-width: 200px;

        @include mqSmallScreen {
          margin: $padding-medium 0 0 0;
        }
      }
    }
  }
}

.introduction--section {
  padding-top: 100px !important;
  padding-bottom: 50px;
  background-color: #ececec;

  .boxed__pic__modal {
    padding: 0 30px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 600 !important;
    position: relative;
    padding-bottom: 20px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 50px);
      background-color: black;
      bottom: 0;
      left: 0;
    }
  }
  .flex-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    // max-width: 500px;
  }
}

@media (min-width: 768px) {
  .introduction--section {
    padding-top: 3em;
    padding-bottom: 3em;
    .flex-wrapper {
      flex-direction: row;
      gap: 0;
      // max-width: 800px;
      &.rev {
        flex-direction: row-reverse;
        h3 {
          &::after {
            right: 0;
            left: initial;
          }
        }
      }
    }
    .boxed__text {
      overflow: hidden;
    }
  }
}

// Map Section
.map--section {
  padding-top: $padding-big;
  padding-bottom: $padding-big;
  padding: 0 !important;
  padding-bottom: 20px !important;
  > .container {
    padding: 0 !important;
  }
  @include mqSmallScreen {
    padding-top: $padding-xl;
  }

  .map-section__map-container {
    display: block;

    @include mqTablet {
      padding-bottom: 0;
    }

    > iframe {
      width: 100%;
      height: 420px;
    }
  }
  .map-section__notification {
    padding: 0 15px;
    span {
      margin-bottom: 0;
      margin-top: 6px;
    }
  }

  .map-section__text {
    padding: 0 30px;
    .h1 {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 30px;
      line-height: 120%;
      position: relative;
      text-transform: uppercase;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 120%;
        background-color: black;
        bottom: -15px;
        left: 0;
      }
    }
    @include mqSmallScreen {
      margin-top: -42px;
      padding: 0 15px;
    }
  }
}

// Departures section
.departures--section {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
  .alert-text {
    padding-left: 15px;
    border-left: 10px solid #eecf3b;
  }
  .h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 30px;
    position: relative;
    text-transform: uppercase;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 120%;
      background-color: black;
      bottom: -15px;
      left: 0;
    }
  }
  @include mqTablet {
    padding-bottom: $padding-huge;
  }

  @include mqSmallScreen {
    padding-bottom: $padding-xl;
  }

  .departures__img-container {
    position: relative;

    .departures__img {
      position: relative;
      display: block;
      height: auto;
      width: 100%;
      object-fit: cover;
      object-position: center;
      max-height: 420px;
      max-width: unset;

      @include mqSmallScreen {
        object-position: 50% 75%;
      }
    }
  }
}

// Ship route
.ship-route--section {
  padding: $padding-big 0;
  position: relative;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #ececec !important;
  .h1 {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: 400;
    line-height: 120%;
    position: relative;
    text-transform: uppercase;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 120%;
      background-color: black;
      bottom: -15px;
      left: 0;
    }
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: -65px;
  //   top: -180px;
  //   width: 420px;
  //   height: 420px;
  //   background-image: url("../../img/graphic_assets/decor-lines-A.svg");
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;
  //   z-index: -1;
  //   transform: rotate(-36deg);
  //   opacity: 0.075;
  // }

  @include mqSmallScreen {
    padding: $padding-xl 0;

    &::after {
      left: -120px;
      top: -250px;
      width: 650px;
      height: 650px;
    }
  }

  .ship-route__map {
    height: 100%;
    width: 100%;

    @include mqSmallScreen {
      padding-right: 28px;
    }
    @media (max-width: 1050px) {
      max-width: 800px;
      margin: auto;
    }

    > img {
      object-fit: cover;
      box-shadow: -35px -10px 40px rgba($color: #5e5c64, $alpha: 0.07);
    }
  }

  .ship-route__text {
    .ship-route__subtitle {
      font-size: $textsize-title-medium;
      color: color(dark);
      line-height: 140%;
      margin-bottom: 0;
      padding-bottom: $padding-small;
    }

    .ship-route__list {
      > li {
        display: flex;
        width: 100%;
        gap: 20px;
        justify-content: flex-start;
        padding-bottom: $padding-medium;

        @include mqSmallScreen {
          flex-direction: row;
          flex-wrap: nowrap;
        }

        > i {
          flex-shrink: 0;
          align-self: center;
          display: block;
          position: relative;
          width: 40px;
          height: 40px;

          margin-bottom: 10px;

          &:first-of-type {
            margin-top: 10px;
          }

          @include mqSmallScreen {
            margin-right: $padding-small;

            &:first-of-type {
              margin-top: 0;
            }
          }

          &.route-point {
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              display: block;
              background-repeat: no-repeat;
              background-size: contain;
            }

            &.coffee {
              &::after {
                background-image: url(../../img/graphic_assets/route-icon-coffee.svg);
              }
            }

            &.photo {
              &::after {
                background-image: url(../../img/graphic_assets/route-icon-photo.svg);
              }
            }

            &.wine {
              &::after {
                background-image: url(../../img/graphic_assets/route-icon-wine.svg);
              }
            }

            &.gramophone {
              &::after {
                background-image: url(../../img/graphic_assets/route-icon-gramophone.svg);
              }
            }
            &.binocular {
              &::after {
                background-image: url(../../img/graphic_assets/route/route-icon-binoculars.svg);
              }
            }
          }
        }

        > p {
          // font-size: 14px;
          // color: color(dark);
          // display: block;
          width: 100%;

          @include mqSmallScreen {
            text-align: left;
          }
        }
      }
    }
  }
}

// Infos section
.info--section {
  position: relative;
  padding-top: 5em;
}

.title-heights {
  .tickets-title {
    font-size: 25px !important;
    font-weight: 600 !important;
    letter-spacing: 2px;
  }
}

// Programs section
.programs--section {
  .programs__title {
    margin-bottom: $padding-medium !important;
  }

  .program {
    margin-top: 0;
    margin-bottom: $padding-medium;
    padding-bottom: 22px;

    @include mqSmallScreen {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 3em;

      @include mqTablet {
        margin-bottom: 0;
      }
    }

    span.program__icon {
      display: block;
      position: absolute;
      top: 32px;
      left: 0;
      height: 60px;
      width: 110px;
      background-color: color(primary);

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 44px;
        width: 44px;
      }

      &.borhajo::after {
        background-image: url(../../img/icons/wine-glasses-white.svg);
      }

      &.naplemente-tura::after {
        background-image: url(../../img/icons/sunset-white.svg);
      }
    }

    .program__header {
      padding-bottom: 45px;
      padding-left: 90px;

      @include mqTablet {
        padding-left: 80px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        line-height: 100%;
      }
    }

    .program__footer-wrapper {
      margin-top: 6px;
      width: 100%;

      hr {
        margin: 20px 0;
      }

      .program__footer-item-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        .program__footer-item {
          margin: 0;
          padding: 0;
          width: 50%;

          &:last-of-type {
            text-align: right;
          }

          &.header-item {
            flex-basis: 100%;
            padding-bottom: 4px;
            line-height: 100%;
          }
        }
      }
    }

    .program-notice {
      height: 30px;
      padding: 10px;
      display: block;
      background-color: color(primary);
      margin-bottom: 10px;

      .p {
        padding: 0;
        line-height: 10px;
        color: black;
      }
    }
  }
}

// Video section
.video--section {
  padding: 0;
  line-height: 0;
  position: relative;

  &.videoplaying {
    .video__container::after {
      opacity: 0;
    }

    .video__preview {
      display: none;
    }
  }

  .video__container {
    &::after {
      content: "";
      position: absolute;
      background-color: black;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      opacity: 0.65;
      transition: opacity 0.3s;
      z-index: 5;
    }

    .video__preview {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: opacity 0.3s;
      }

      &.hiding {
        display: none;
        opacity: 0;

        &::after {
          content: none;
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
      z-index: 0;
      position: relative;
    }
  }

  .video__title-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    z-index: 10;

    > .video__title {
      color: white;
    }

    > .video__btn {
      width: 64px;
      height: 64px;
      top: 0;
      left: 0;
      transition: all 0.5s ease;
      display: block;
      z-index: 1;
      transform-origin: center;
      transform: scale(1);
      opacity: 1;

      @include mqSmallScreen {
        width: 80px;
        height: 80px;
      }

      &.hiding {
        opacity: 0;
        transform: scale(0.7);
      }

      &.play {
        background-image: url(../../img/icons/play-button-primary-col.svg);
        top: 0;
        left: 0;
        opacity: 1;
      }
    }
  }
}

// Slider section
@media (max-width: 990px) {
  .flickity-prev-next-button {
    display: initial;
  }
}

.gallery--section {
  padding: 0;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  margin: 0;
  background-color: white;
  .home-gallery-wrap {
    height: 350px;
    @media (min-width: 600px) {
      height: 400px;
    }
    @media (min-width: 900px) {
      height: 500px;
    }
    @media (min-width: 1100px) {
      height: 450px;
    }
    .swiper {
      height: 100%;
      width: 100%;
      position: relative;
      img {
        height: 100%;
        object-position: center;
      }
      .swiper-button-prev,
      .swiper-button-next {
        background-color: #eecf3b;
        opacity: 0.85;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 1;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        border-radius: 50%;
        cursor: pointer;
        &::after {
          content: "";
          width: 30px;
          height: 30px;
          background-image: url(../../img/graphic_assets/arrow.svg);
        }
      }
      .swiper-button-next {
        right: 0;
        left: initial;
        &::after {
          transform: scale(-1, -1);
        }
      }
    }
  }
  h2 {
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 400;
  }
  .swiper-slide {
    height: 100%;
  }
}
.image-slider {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

[class*="slide__"] {
  width: 100%;
  max-width: 650px;
  position: relative;
}

[class*="slide__"] .img-container {
  // width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  display: block;
  @media (max-width: 500px) {
    height: 350px;
  }
}

[class*="slide__"] .img-container img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  width: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

[class*="slide__"] img:not(:last-child) {
  padding-right: 1em;
}

// FAQ section
.faq--section {
  .accordion-title-margin {
    margin: 0 20px;
  }

  .faq__img {
    height: 700px;
    width: 100%;
    //position: relative;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .accordion.ui.fluid.styled {
    @include size(max, tablet, xxxmedium) {
      margin-bottom: 20px;
    }

    .accordion-text-margin {
      margin: 0 20px;
    }
  }

  .accordion .accordion__title {
    position: relative;
    //  opacity: 0.65;
  }
  .accordion__title,
  .accordion li:last-child .accordion__title {
    border-color: black;
  }

  .accordion .accordion__title:before {
    content: "\e80c";
  }

  .accordion__title span,
  .accordion-1 li.active .accordion__title span,
  .accordion .accordion__title:before,
  .accordion-1 li.active .accordion__title:before {
    color: black;
  }

  .accordion-1 li.active .accordion__title {
    background-color: color(primary);
    border-color: black;
  }

  .accordion__title:before {
    position: absolute;
    left: 10px;
    top: 7px;
  }

  .accordion__title span {
    margin-left: 12px;
  }

  .question:last-child .accordion .accordion__title {
    margin-bottom: initial;
  }

  .question-row {
    @include mqSmallScreen {
      &.col-12 {
        padding-left: 2px;
      }
    }
  }

  .question {
    @include mqSmallScreen {
      &.col-12 {
        padding-left: 1px;
      }
    }
  }

  @media (max-width: 768px) {
    .faq--section .question:last-child {
      padding-bottom: 2em;
    }

    .question:last-child .accordion .accordion__title {
      margin-bottom: 0;
    }
  }
  .title {
    display: flex;
    border: 1px solid black !important;
    border-radius: 8px;
    justify-content: space-between;
    padding-right: 20px !important;
    flex-direction: row-reverse;
    &.active {
      color: white !important;
      background-color: #252525 !important;
      border-radius: 8px 8px 0 0;
    }
  }
  .content {
    &.active {
      border: 1px solid black;
      border-radius: 0 0 8px 8px;
      padding-bottom: 20px !important;
      border-top: none;
    }
  }
}

// Form section
.form--section:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;

  background-size: cover;
  background-position: center;
  opacity: 0.08;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

// Email section
.email--section {
  position: relative;
  background-color: color(dark);
  margin: 0;
  padding: 0;
  padding-top: 0 !important;
  width: 100%;

  @include mqSmallScreen {
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: -130px;
      right: -140px;
      width: 650px;
      height: 350px;
      background-image: url(../../img/graphic_assets/decor-lines-A.svg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(35deg);
      opacity: 0.65;
    }
  }

  .email-section__bckgrnd-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.75);
    }

    @include mqSmallScreen {
      position: relative;
      height: auto;

      &::after {
        background-color: transparent;
      }
    }

    .email-section__bckgrnd-img {
      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;

      @include mqMobile {
        background-image: url(../../img/512/email-section-bckgrnd-01-512x.jpg);
      }

      @include mqTablet {
        background-image: url(../../img/768/email-section-bckgrnd-01-768x.jpg);
      }
    }
  }

  .email-section__text-container {
    padding: $padding-huge 0;

    @include mqSmallScreen {
      padding: 150px $padding-big 150px $padding-big;
    }

    .email-section__title {
      font-size: 32px;
      color: white;
    }
    .email-section__subtitle {
      color: white;
      font-size: $textsize-lead;
    }

    .email-address-container {
      text-align: center;
      display: block;

      > .email-address {
        color: white;
        font-size: 22px;
        line-height: 100%;
        padding: 0;
        margin: 0;
        height: 32px;
      }
    }

    .email-section__button {
      display: block;
      width: 100%;
      position: relative;
      padding: 12px 0;

      &:hover {
        transform: none;
      }

      > i {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 36px;
        font-weight: bold;
      }

      span {
        font-size: $textsize-body;
        font-weight: 900;
        text-transform: uppercase;
        padding-left: 18px;
      }
    }
  }
}

// Brand reference section
.brand-ref--section {
  padding: 60px 0;

  .brand-ref__img-container {
    margin-bottom: 20px;

    @include mqMediumScreen {
      margin-bottom: 0;
    }
  }

  .brand-ref__text-container {
    text-align: center;

    @include mqLargeScreen {
      text-align: left;
    }

    h4 {
      margin-bottom: 6px;
    }
    h1 {
      margin-bottom: 12px;
    }
  }
}

// Additional info section
.additional-info--section {
  background-color: rgba(2, 32, 33, 0.05);
  padding: 4em 0;
}

.home-page-sender-form-section {
  padding: 3em 0;
  .focus-border {
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
  }
  .error-message {
    color: red;
  }

  .success-message {
    color: green;
  }

  .MuiCardContent-root {
    padding: 0 !important;
  }
  .form-wrapper {
    margin-top: 50px;

    .center-rb-left-cols {
      display: flex;
      justify-content: center;
    }

    .start-rb-right-cols {
      display: flex;
      justify-content: flex-start;
    }
    .writer-machine {
      max-width: 570px;
      padding: 10px 50px 10px 20px;
    }
    .MuiInputBase-multiline {
      padding: 0 0 0 0;
    }

    .MuiInput-underline {
      &:after {
        border-bottom: none !important;
      }
    }
    .email-form__input {
      &.email-form__textarea {
        padding: 0 !important;
      }
    }

    .email-form textarea {
      padding: 16px 14px;
    }
    .form--subtitle {
      margin-bottom: 25px;
    }

    .form--title {
      max-width: 99%;
    }
    .orientation-center {
      text-align: center;
    }

    .btn-sender-homePage {
      justify-content: flex-end;
      display: flex;
    }

    img {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    .btn {
      min-width: 120px !important;
      margin: 0 0 0 0 !important;
      padding: 0 0 0 0 !important;
    }

    .email-form__button {
      margin: 0 0 0 0 !important;
      padding: 0 20px 20px 20px !important;
    }

    .email-form__input {
      .MuiInput-underline {
        &::before {
          border-bottom: none !important;
        }

        .form__input-row {
          textarea {
            border-color: rgba($color: white, $alpha: 0.25) !important;

            &::placeholder {
              font-size: 16px;
            }

            &:active {
              border-color: white;
            }

            &:focus {
              border-color: white;
            }
          }
        }
      }
      .MuiCardContent-root {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        &.Mui-focused {
          fieldset,
          textarea {
            border: none !important;
            opacity: 0;
          }
        }
      }
    }
    .btn-sender-homePage {
      background-color: rgba(231, 231, 230, 0.25);
      justify-content: space-between;
    }
    .btn.hero-CTA-homePage {
      display: block;
      margin: $padding-medium auto 0 auto;
      max-width: 120px;

      @include mqSmallScreen {
        margin: $padding-medium 0 0 0;
      }
    }
  }

  .email-form .MuiInputBase-root.Mui-focused {
    textarea {
      border: none !important;
    }
    .textarea-with-button {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
  }

  .textarea-with-button {
    margin-bottom: 12px;
    border-radius: 5px;
    background-color: rgba(231, 231, 230, 0.25);
    border: 1px solid #d3d3d3;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87);
      input {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    &:active {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
  }
  .charCounter {
    color: #d3d3d3;
    line-height: normal;
    vertical-align: bottom;
    width: 100%;
    margin-top: auto;

    .counter-span {
      line-height: normal;
      vertical-align: bottom;
      width: 100%;
      margin-top: auto;
    }
  }
}

.title-wrapper-tickets {
  overflow-y: hidden;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;

  h3 {
    font-size: 25px !important;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .title-1 {
    // border-right: 1px solid white;
    // border-bottom: 5px solid #eecf3b;
    width: 100%;
    h3 {
      margin-bottom: 0.2em;
    }
    span {
      font-weight: 500;
    }
  }

  .title-2 {
    border-bottom: 5px solid #eecf3b;
    width: 75%;
  }
}

.title-heights {
  height: 69px;
}

.ticket__addendum {
  font-size: 13px;
}

.square-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 5px;
  color: yellow;
  margin-right: 5px;
  margin-left: 5px;
}

.info-border {
  color: color(primary) !important;
  background-color: black;
  border-radius: 50%;
  border: 1px solid black;
}

.fas.fa-arrow-right {
  &.middle {
    font-size: 1.1em;
  }
}

.relative-wrapper {
  position: relative;
}
.bar_pikto {
  width: 38px !important;
  vertical-align: bottom;
  margin-right: 12px;

  &.home-icon-class {
    position: absolute;
    left: 18px;
    top: 25%;
    transform: translate(-50%, -50%);

    @include mqMobileSmall {
      top: 50%;
    }
    @include mqTablet {
      top: 25%;
    }

    @include mqSmallScreen {
      top: 50%;
    }
  }
}

.main-card-padding {
  padding: 30px;
  padding-top: 0;
}
.easter {
  width: 15px;
}
.notification-container {
  padding: 20px 100px;
  h3 {
    margin: 0;
  }
  &.tuzi {
    position: relative;
    background-image: url(../../img/tuzi2.jpg);
    background-position: 0% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    gap: 10px;
    .firework-text {
      max-width: 600px;
      margin-bottom: 10px;
      text-align: center;
    }
    // .to-ticket {
    //   background-color: #eecf3b;
    //   color: black !important;
    //   padding: 10px 20px;
    //   font-size: 16px;
    //   text-transform: uppercase;
    //   font-weight: 400;
    //   cursor: pointer;
    //   padding-right: 50px;
    //   border-radius: 6px;
    //   position: relative;
    //   &::before {
    //     background-image: url(../../img/graphic_assets/arrow.svg);
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: center;
    //     width: 20px;
    //     height: 20px;
    //     border: none;
    //     content: "";
    //     position: absolute;
    //     transform: scale(-1, -1) translateY(50%);
    //     top: 50%;
    //     right: 15px;
    //   }
    // }

    * {
      color: white !important;
    }
    .btn {
      color: black !important;
      background-color: #eecf3b;
      width: fit-content;
      margin: 0;
      border: none !important;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;

      z-index: -1;
    }
  }

  p {
    max-width: 1200px;
    margin: auto;
    color: black;
    &.p {
      font-size: 16px;
    }
  }
  .valid {
    font-size: 12px;
    line-height: 14px;
    // margin-bottom: 10px;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding: 20px 50px;
    .p {
      font-size: 16px !important;
    }
  }
  @media (max-width: 500px) {
    padding: 20px;
  }
  &.aug20 {
    .flex-wrap {
      box-shadow: none;
      @media (min-width: 1001px) {
        border-radius: 16px;
      }
      .inner-wrap {
        p {
          &.h {
            margin: 0;
            text-align: center;
            font-weight: 600;
            font-size: 24px;
            @media (max-width: 767px) {
              font-size: 20px !important;
            }
          }
        }
      }
    }
    .head-wrap {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      margin-bottom: 10px;
      justify-content: center;
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
      }

      .icon-wrap {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        @media (max-width: 600px) {
          &:nth-of-type(2n) {
            display: none;
          }
        }
      }
    }
    .btn {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
  &.coupon {
    padding: 0;
    width: 100%;
    padding: 20px;
    background-color: #eecf3b;
    .flex-wrap {
      position: static;
      width: 100%;
      max-width: initial;
      &.xmas {
        * {
          // line-height: 1.1 !important;
        }
      }
      .inner-wrap {
        padding: 0;
        display: flex;
        align-items: center;
        .xmas-wrap {
          display: flex;
          column-gap: 10px;
          align-items: flex-start;
          position: relative;
          @media (max-width: 750px) {
            flex-direction: column;
            align-items: center;
          }
          .xmas-img {
            width: 50px;
            object-fit: contain;
            left: 0;
            // position: absolute;
            @media (min-width: 751px) {
              display: none;
            }
          }
        }
        .flex-wrapper {
          display: flex;
          column-gap: 10px;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;

          .copy-btn {
            margin: 5px auto;
            padding: 0;
            background-color: transparent;
            border: none;
            span {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
          p {
            text-align: center;
            line-height: 25px;
            #coupon {
              line-height: 35px;
            }
          }
          b {
            font-weight: 500;
            padding: 5px 10px;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 6px;
            margin: 5px 0;
          }
        }
      }
    }
  }
}
.text-copied-response {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  text-align: center;
  z-index: 20;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #eecf3b;
  width: 100%;
  transition: 0.3s;
  transform: translateY(0);
  p {
    font-weight: 300;
  }
  &.hidden-rp {
    transform: translateY(100%);
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 200vh;
  z-index: 1000000000;
  background-color: rgba(0, 0, 0, 0.3);
  .popup {
    position: fixed;
    max-width: 700px;
    min-width: 320px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
    padding: 20px;
    @media (min-width: 1000px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 50px;
      width: 100%;
      align-content: center;
      gap: 50px;
    }
    .img-container {
      height: 300px;
      width: 100%;
      @media (min-width: 1000px) {
        height: 400px;
      }
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .text-container {
      align-self: center;
      margin-top: 20px;
      @media (min-width: 1000px) {
        margin-top: 0;
      }
      h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        br {
          display: none;
        }
        span {
          text-transform: uppercase;
          font-size: 26px;
          line-height: 22px;
          font-weight: 600;
          text-align: center;
          background-color: #252525;
          color: #eecf3b;
          margin: -2px auto;
          padding: 5px 10px;
          margin: auto;
          // width: max-content;
          max-width: 100%;
          @media (min-width: 1000px) {
            font-size: 34px;
            line-height: 30px;
          }
        }
      }
      .subtext {
        display: block;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding: 0 10px;
      }
      .to-ticket {
        background-color: #eecf3b;
        color: black;
        display: block;
        position: relative;
        border-radius: 6px;
        font-size: 16px;
        cursor: pointer;
        width: fit-content;
        text-align: center;
        font-weight: 500;
        padding: 5px 20px;
        text-transform: uppercase;
        margin: 10px auto;
      }
      p {
        text-align: center;
        font-size: 14px;
        margin: 10px 0;
        font-weight: 500;
        &.first {
          margin-bottom: 0;
        }
        &.bold-text {
          margin: auto;
          margin-top: 0;
          text-transform: uppercase;
          font-size: 16px;
          background-color: #eecf3b;
          padding: 5px;
          width: fit-content;
          position: relative;
        }
      }
      .close {
        top: 10px;
        right: 10px;
      }
      .close-btn {
        cursor: pointer;
      }
      .short {
        margin: 20px auto;
        border-color: #252525;
        opacity: 0.8;
        border-width: 1px;
      }
      .sub-texts {
        p {
          font-size: 16px;
          max-width: 250px;
          margin: 10px auto;
        }
      }
    }
  }
}
.gradient-wrap {
  position: fixed;
  bottom: 0;
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), transparent);
  height: 230px;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 1000px);

  // background-color: #eecf3b;
  width: 100%;

  transition: 1s;
  &.visible {
    transform: translate(-50%, 0);
  }
  .banner {
    position: fixed;
    bottom: 20px;
    background-color: #eecf3b;
    z-index: 10000;
    left: 50%;
    transform: translate(-50%, 1000px);
    display: flex;
    gap: 20px;
    padding: 20px;
    border-radius: 8px;
    width: 95%;
    max-width: 800px;

    transition: 1s;
    &.visible {
      transform: translate(-50%, 0);
    }

    @media (max-width: 650px) {
      flex-direction: column;
      gap: 0;
    }
    h3 {
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 650px) {
        margin: 0;
      }
    }
    p {
      margin-bottom: 20px;
      @media (max-width: 650px) {
        margin: 0;
      }
    }
    .btn-wrp {
      display: flex;
      justify-content: flex-end;
      .to-ticket {
        color: black;
        position: relative;
        padding: 5px 0;
        padding-right: 30px;
        border-bottom: 1px solid black;

        &::before {
          background-image: url(../../img/graphic_assets/arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 20px;
          height: 20px;
          border: none;
          content: "";
          position: absolute;
          transform: scale(-1, -1) translateY(50%);
          top: 50%;
          right: 0;
        }
      }
    }
  }
}
.main-container {
  // filter: blur(3px);
  // filter: grayscale(1);
}
.flag {
  margin-left: 10px;
  padding: 5px 5px 0;
  display: none;
}
.flotta-wrapper {
  background-color: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 50px;
  * {
    font-family: "Jost", sans-serif !important;
  }
  @media (max-width: 850px) {
    padding: 50px;
  }
  @media (max-width: 550px) {
    padding: 50px 20px;
  }
  .flotta-section {
    max-width: 1400px;
    width: 100%;
    box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.3);
    display: grid;
    background-color: white;
    grid-template-columns: 1fr 1fr;
    border-radius: 16px;
    overflow: hidden;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      width: 500px;
    }
    @media (max-width: 550px) {
      max-width: 400px;
    }
    .text-wrapper {
      position: relative;
      padding: 50px;

      background-color: white;
      display: grid;

      @media (max-width: 900px) {
        width: 100%;
        margin: auto;
        padding: 20px;
      }

      p {
        margin: 20px auto;
        font-size: 16px;
        max-width: 320px;

        &.big {
          text-align: center;
          font-size: 20px;
          padding: 0 30px;
          margin-bottom: 10px;
        }
      }
      h3 {
        font-weight: 600;
        text-align: center;
        font-size: 32px;
        position: relative;
        max-width: 320px;
        margin: 0 auto 20px;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding-bottom: 10px;
        line-height: 28px;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 1px;
        //   background-color: black;
        //   @media (max-width: 900px) {
        //     content: none;
        //   }
        // }
      }
    }
    .to-ticket {
      align-self: flex-end;
      background-color: #d9ae63;
      color: black;
      padding-right: 50px;
      position: relative;
      padding: 5px 40px 5px 20px;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 300;
      margin: 20px auto;
      display: block;
      width: fit-content;
      font-size: 14px;
      font-weight: 400;
      &::before {
        // background-image: url(../img/graphic_assets/arrow.svg);
        background-image: url(../../img/graphic_assets/arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 20px;
        height: 20px;
        border: none;
        content: "";
        position: absolute;
        transform: scale(-1, -1) translateY(50%);
        top: 50%;
        right: 15px;
      }
    }
    .bttn {
      background-color: #d9ae63;
      border: 1px solid #d9ae63;
      margin: auto;
      cursor: pointer;
      width: fit-content;
      border-radius: 6px;
      a {
        padding: 10px 40px 10px 15px;
        gap: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        height: 100%;
      }
      span {
        font-weight: 300;
        pointer-events: none;
        text-transform: capitalize;
        text-align: center;
        z-index: 1;
        font-size: 16px;
        line-height: 22px;
      }
      .arr {
        background-image: url(../../img/icons/arrow-inactive.svg);
        background-position: center;
        background-repeat: no-repeat;

        width: 25px;
        height: 25px;
        pointer-events: none;
        right: 10px;
        position: absolute;
        z-index: 0;
      }
    }
    .img-wrapper {
      overflow: hidden;
      background-color: white;
      padding: 20px;
      display: flex;
      align-items: center;
      img {
        border-radius: 16px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 100%;
        max-height: 400px;
      }
      @media (max-width: 900px) {
        margin: 20px 0;
        img {
          max-height: 300px;
        }
      }
      @media (max-width: 550px) {
        height: 250px;
        padding: 0;
      }
    }
    .brand-wrapper {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      padding: 15px 30px;
      min-width: 200px;
      // background-color: #252a30;

      img {
        width: 100%;
      }
      h4 {
        color: #d9ae63;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 0 0;
      }
    }
  }
}
