.spinner-loader {
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;
    padding: 0 24px;
    margin-bottom: 12px;

    .loader__inner-content {
        display: flex;
        align-items: center;
        column-gap: 12px;

        > span {
            text-transform: uppercase;
            color: color(dark-accent);

            &.css-1oomt41 {
                width: 16px;
                height: 16px;
            }
        }
    }
}
