.about-wrapper {
  background-color: #252525;
  padding: 100px 0;
  @media (max-width: 950px) {
    padding: 50px 0;
  }
  h3 {
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    color: white;
    line-height: 50px;
    max-width: 800px;
    padding: 0 20px 10px;
    margin: 0;
    padding-left: 100px;
    max-width: 800px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% + 100px);
      background-color: white;
      bottom: 0;
      left: -250px;
    }
    @media (max-width: 1200px) {
      line-height: 52px;
      font-size: 46px;
      padding-left: 100px;
    }
    @media (max-width: 950px) {
      padding-left: 50px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 44px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .text-wrapper {
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    gap: 50px;
    @media (max-width: 950px) {
      gap: 20px;
      padding: 50px 50px 0 50px;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 650px) {
      padding: 30px 20px 0;
    }
    p {
      max-width: 500px;
      position: relative;
      margin-bottom: 20px;
      line-height: 30px;
      font-size: 16px;
      font-weight: 300;
      color: white;
      break-inside: avoid;
      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 24px;
      }
      &.large {
        font-size: 18px;
        line-height: 26px;

        @media (max-width: 1200px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    .text-inner {
      columns: 3;
      column-gap: 40px;

      @media (max-width: 1200px) {
        columns: 2;
      }
      @media (max-width: 950px) {
        align-items: center;
      }
      @media (max-width: 750px) {
        columns: 1;
      }
    }
  }
}
