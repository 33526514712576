[class*="bg--"] {
    position: relative;
}

.bg--lightm {
    background-color: rgba(2, 32, 33, 0.045);
}

[class*="bg--"].texture-dark::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-image: url("https://www.transparenttextures.com/patterns/concrete-wall.png");
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.45;
}