.gallery-page-wrap {
  background-color: #1a2027;
  padding-top: 75px;
  padding-bottom: 10px;
  .mobile-wrap {
    margin: 50px 0 100px;
    h2 {
      color: white;
      margin-bottom: 50px;
    }
  }
  .desktop-h {
    color: white;
    margin-bottom: 50px;
    @media (min-width: 1280px) {
      margin-top: 50px;
    }
  }
  .desktop-wrap {
    margin: 50px 0;

    flex-direction: column;
    gap: 10px;
    @media (min-width: 600px) {
      display: flex;
    }
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .gallery-page {
    display: grid;
    width: 100%;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-auto-rows: 120px;
    overflow: hidden;

    grid-auto-flow: row dense;

    // &.b-col {
    //   grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    //   @media (max-width: 1600px) {
    //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    //   }
    // }
    .img-wrap {
      grid-column-start: auto;
      grid-row-start: auto;
      grid-row-end: span 2;
      grid-column-end: span 2;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.large {
        grid-row-end: span 4;
        grid-column-end: span 4;
      }
      &.long {
        grid-row-end: span 4;
        grid-column-end: span 2;
      }
      &.full {
        grid-column-end: auto;

        @media screen and (min-width: 768px) {
          grid-column: 1/-1;
          grid-row-end: span 2;
        }
      }
      &:last-of-type {
        // grid-row-end: -1;
      }
    }
  }

  .swiper {
    &.thumbSwiper {
      padding-top: 10px;
    }
  }
}
.pswp__img {
  object-fit: cover;
}
