.popup-wrapper {
position: absolute;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.5);
z-index: 1001;
}
.popup--kidsday {
  max-width: 500px;
  width : 50%;
  position : absolute;
  z-index: 1002;
  top : 120px;
  left : 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px  rgba(0,0,0,1);
  text-align: center;
  p {
    margin-top: 20px
  }
  @media (max-width : 650px) {
    width : 70%;
  }
  @media (max-width : 500px) {
    width : 90%;
  }

  .close-btn {
    position : absolute;
    top : 20px;
    right : 20px;
    cursor : pointer ;
    width : 20px;
    display: flex;
    justify-content: center;
  }
  .balloon {
    position: absolute;
    left :50px;
    top : 40px;
    @media (max-width : 400px) {
      left : 30px;
    }
  }
 
  .btn {
    width : fit-content
  }
}