.find-us-wrap {
  background-color: white;
  padding: 100px 0 0;
  @media (max-width: 950px) {
    padding: 50px 0;
  }
  h3 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    margin: auto;
    padding: 0 20px 10px;
    max-width: 800px;
    text-align: center;
    @media (max-width: 1200px) {
      line-height: 52px;
      font-size: 46px;
    }
    @media (max-width: 800px) {
      font-size: 38px;
      line-height: 44px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .map-text-wrap {
    display: flex;
    padding: 100px 100px;
    gap: 50px;
    max-width: 1600px;
    margin: 0 auto;
    align-items: center;
    @media (max-width: 950px) {
      padding: 50px 50px 0;
      flex-direction: column-reverse;
      align-items: stretch;
    }
    @media (max-width: 650px) {
      padding: 20px 0 0;
    }
    .text-wrap {
      flex-basis: 50%;
      @media (max-width: 650px) {
        padding: 0 20px;
      }
      .address {
        font-size: 16px;
        color: black;
        font-weight: 300;
        margin-bottom: 20px;
      }
      .address-desc {
        margin-bottom: 30px;
        line-height: 24px;
        color: black;
        font-weight: 300;
        font-size: 16px;
      }
      .map-section__notification {
        background-color: white;
        box-shadow: 0 0 18px 2px rgba(0, 0, 0, 0.2);

        display: flex;
        align-items: stretch;
        border-radius: 20px;
        .text-wrapper {
          display: flex;
          align-items: center;
        }
        .img-wrap {
          background-color: #eecf3b;
          width: 65px;
          border-radius: 20px 0 0 20px;
          min-width: 50px;
          overflow: hidden;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          background-color: #eecf3b;
        }
        p {
          font-size: 16px;
          padding: 10px 20px;

          color: black;
          font-weight: 300;
          @media (max-width: 1200px) {
            font-size: 14px;
          }
        }
      }
    }
    .map {
      flex-basis: 50%;
      padding: 20px;
      background-color: #f2f2f2;
      border-radius: 20px;

      box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.3);
      iframe {
        height: 400px;
        border-radius: 20px;
      }
      @media (max-width: 650px) {
        padding: 20px;
        border: 0;
        box-shadow: none;
        background-color: white;

        iframe {
        }
      }
    }
    .map-placeholder {
      height: 400px;
      width: 100%;
      background-color: white;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
