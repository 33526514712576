
.page-not-found--section {
    padding-top: 300px !important;
    padding-bottom: 100px;
    height: 100%;
    position: relative;

    .page-not-found__container {
        .page-not-found__illustration {
            display: block;
            height: 400px;
            width: auto;
            background-image: url(../../img/graphic_assets/lighthouse_illustration-01.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .page-not-found__errorcode {
            font-size: 70px;
            margin: 0;
            padding: 0;
            color: color(primary);

            @include mqTablet {
                font-size: 145px;
            }
        }

        .page-not-found__text {
            padding-top: 0;
            p {
                color: color(dark);
                font-size: $textsize-subtitle;
                font-weight: bold;
                padding: 0;
                margin: 0;
                line-height: normal;

                &:first-child {
                    margin-bottom: 10px;
                }

                &:not(:first-child) {
                    font-size: $textsize-body;
                    font-weight: normal;
                }
            }
        }

        .page-not-found__btn {
            margin-top: 12px;
            text-align: left;

            > a {
                margin: 0 auto;
                display: block;
                color: color(dark);
                text-align: left;
                vertical-align: middle;
                line-height: 100%;

                > i {
                    vertical-align: middle;
                }
            }
        }
    }
}